<script setup lang="ts">
import { defineProps, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import { useUserStore } from '@/store/user/userStore'

const subscriptionStore = useSubscriptionStore()
const userStore = useUserStore()

const { t } = useI18n()

const oldPrice = computed(() => {
  return subscriptionStore.subscriptionStatus['price'] ?? 50
})

const currentPrice = computed(() => {
  return subscriptionStore.subscriptionStatus['actualPrice'] ?? 37.5
})

const tva = computed(() => {
  return subscriptionStore.subscriptionStatus['tva']
})

const message = computed(() => {
  if (subscriptionStore.subscriptionStatus['type'] == 'life') {
    return t('dashboard.subscription.lifetime')
  } else if (
    // presubscription
    subscriptionStore.subscriptionStatus['type'] === 'monthly' &&
    subscriptionStore.subscriptionStatus['monthsAlreadyPaid'] > 0
  ) {
    // abonnement en cour d'activation ou activé
    if (userStore.user.isPendingApproval || userStore.user.isActive) {
      return t('dashboard.subscription.pre_subscription_message_new', {
        value: subscriptionStore.subscriptionStatus['monthsAlreadyPaid'],
      })
    } else {
      return t('dashboard.subscription.pre_subscription_message2', {
        value: subscriptionStore.subscriptionStatus['monthsAlreadyPaid'],
      })
    }
  } else {
    if (userStore.user.isTrialing) {
      return t(
        'dashboard.subscription.subscription_message_no_subscription_active_trial',
      )
    } else {
      // période d'éssai terminé
      // paiement terminé
      if (
        subscriptionStore.subscriptionStatus['status'] == 'incomplete_expired'
      ) {
        return t(
          'dashboard.subscription.subscription_message_no_subscription_active',
        )
      } else {
        return ''
      }
    }
  }
})

const blocMessage = computed(() => {
  if (subscriptionStore.subscriptionStatus['type'] == 'annually') {
    return t('annual_bloc_msg')
  }
  return t('offer_valid')
})

const remainingDays = computed(() => {
  if (subscriptionStore.subscriptionStatus['trial']) {
    const today = dayjs()
    const target = dayjs(subscriptionStore.subscriptionStatus['trial'])
    const day = target.diff(today, 'day')
    if (day > 0) {
      return day
    }
    return 0
  }
})

const nextBillDate = computed(() => {
  return subscriptionStore.subscriptionStatus['nextBillDate']
})
</script>

<template>
  <v-card
    color="white"
    border-color="blue"
    border="md"
    max-width="600"
    class="pa-3 elevation-0"
  >
    <div class="d-sm-flex justify-between align-center w-100">
      <!-- abonnement a vie  -->
      <div
        v-if="subscriptionStore.subscriptionStatus['type'] === 'life'"
        style="font-size: 24px"
        class="w-sm-50 w-100 d-flex justify-center align-center font-italic text-secondary"
      >
        <v-icon icon="mdi-check-circle"></v-icon>
        <span>{{ message }}</span>
      </div>

      <!-- pre-subscription user  -->
      <div
        class="w-sm-50 w-100"
        v-else-if="
          subscriptionStore.subscriptionStatus['type'] === 'monthly' &&
          subscriptionStore.subscriptionStatus['monthsAlreadyPaid'] > 0
        "
      >
        <div
          style="font-size: 21px"
          class="d-flex justify-center align-center font-italic text-secondary"
        >
          <v-icon icon="mdi-check-circle"></v-icon>
          <span>{{ message }}</span>
        </div>
        <div style="color: #616161" class="my-1 text-caption font-weight-bold">
          <p class="" v-if="nextBillDate">
            {{ t('nextPaiementDate') }}:
            {{ dayjs(nextBillDate).format('D MMMM YYYY') }}
          </p>
          <p class="">
            <span>{{ t('amount') }}: </span>
            <span class="font-weight-bold"
              >{{ currentPrice }}€ {{ tva > 0 ? 'TTC' : 'H.T' }}</span
            >
          </p>
        </div>
      </div>

      <div v-else class="w-sm-50 w-100 d-flex justify-center align-center">
        <div>
          <div class="text-h6 d-flex justify-center align-center">
            <template v-if="oldPrice">
              <span class="old_price text-green-100 text-h6 pa-2"
                >{{ oldPrice }}€</span
              >
            </template>
            <div class="d-flex align-center">
              <span class="text-secondary text-h4 mx-2">
                <strong>{{ currentPrice }}</strong>
              </span>
              <span class="text-body-2"
                >€ {{ tva > 0 ? 'TTC' : 'H.T' }} /{{
                  subscriptionStore.subscriptionStatus['type'] == 'annually'
                    ? 'an'
                    : 'mois'
                }}</span
              >
            </div>
          </div>

          <div class="bg-secondary px-2 rounded-lg text-subtitle-2 text-center">
            <span>{{ blocMessage }}</span>
          </div>

          <div
            style="color: #616161"
            class="my-1 text-caption font-weight-bold"
          >
            <p class="" v-if="subscriptionStore.subscriptionStatus['trial']">
              <span>{{ t('trial_period') }}: </span>
              <span v-if="remainingDays > 0" class="font-weight-bold"
                >{{ t('remaining_date', { value: remainingDays }) }}
              </span>
              <span
                v-else
                v-html="
                  t('remaining_date_pass', {
                    value: dayjs(
                      subscriptionStore.subscriptionStatus['trial'],
                    ).format('D MMMM YYYY'),
                  })
                "
              ></span>
            </p>

            <p class="" v-if="nextBillDate">
              {{ t('nextPaiementDate') }}:
              {{ dayjs(nextBillDate).format('D MMMM YYYY') }}
            </p>

            <p class="">
              <span>{{ t('amount') }}: </span>
              <span class="font-weight-bold"
                >{{ currentPrice }}€ {{ tva > 0 ? 'TTC' : 'H.T' }}</span
              >
            </p>
          </div>

          <div v-if="remainingDays > 0" class="font-italic mt-1 text-secondary">
            <v-icon icon="mdi-check-circle"></v-icon>
            <span>{{ message }}</span>
          </div>
          <div
            v-else-if="
              remainingDays == 0 &&
              subscriptionStore.subscriptionStatus['status'] ==
                'incomplete_expired'
            "
            class="font-italic mt-1 text-secondary"
          >
            <v-icon icon="mdi-check-circle"></v-icon>
            <span>{{ message }}</span>
          </div>
        </div>
      </div>

      <!-- block des avantages  -->
      <div class="w-sm-50 w-100 text-center">
        <div>
          <span>{{ t('appointment_booking') }}</span
          ><br />
          <v-icon color="secondary">mdi-plus</v-icon>
        </div>
        <div>
          <span>{{ t('up_profiles') }}</span
          ><br />
          <v-icon color="secondary">mdi-plus</v-icon>
        </div>
        <div>{{ t('up_adresses') }}</div>
        <div>
          <v-icon color="secondary">mdi-dots-horizontal</v-icon>
        </div>
      </div>
    </div>
  </v-card>
</template>
