import { computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import MeetingsDay from 'vue-meeting-selector/src/interfaces/MeetingsDay.interface'
import {
  transformDataFromAvailableData,
  AvailableData,
  addMissingDays,
  AugmentedMeetingSlot,
} from '@/shared/AppointmentHelper'
import createHttpClient from '@/api/httpClient'
import {
  AppointmentReasonInformationData,
  FormattedAppointmentReasonInformationData,
} from '@/api/appointment-reason/appointment-reason.d'
import { usePractitionerPublicProfileStore } from './practitionerPublicProfileStore'
import { AddressInformationData } from '@/api/account/address'
import {
  AppointmentTemporaryPayload,
  IAppointmentTemporary,
  IDateWrapper,
  IMessageWrapper,
} from './appointmentManagement.d'
import router from '@/router'
import MessageService from '@/components/feedback/message/messageService'
import i18n from '@/plugins/i18n'
import { ApiResponse } from '@/api/api'
import LoadingBackdropService from '@/components/feedback/loadingBackdrop/loadingBackdropService'
import { placeholderMeetingsDaysEmptyGenerator } from '@/utils/slotsGenerator'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useDebounceFn } from '@vueuse/core'
import { useAbsenceServiceStore } from '../absenceServiceStore/absenceServiceStore'

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = i18n.global
const httpClient = createHttpClient()

/**
 * This is the store used for the management of the patient appointment, in public practitioner profile page
 */
export const useAppointmentManagementStore = defineStore(
  'appointment-management',
  () => {
    const start = ref<dayjs.Dayjs>(dayjs())
    const numberOfDaysDisplayed = ref<number>(3)
    const end = computed(() =>
      start.value
        .add(numberOfDaysDisplayed.value + 1, 'days')
        .hour(0)
        .minute(0)
        .millisecond(0),
    )
    const date = computed(() => start.value.toDate())
    const meetingsDays = ref<MeetingsDay[]>([])
    const temporaryAppointment = ref<IAppointmentTemporary>(null)
    const selectedMeeting = ref<AugmentedMeetingSlot>(null)

    const selectedAddress = ref<AddressInformationData>(null)
    //isRemoteAddress = ref<boolean>(false)
    const remote = ref<'in-person' | 'remote'>('in-person')
    const selectedReason = ref<FormattedAppointmentReasonInformationData>(null)
    const selectedReasonId = ref('')

    const debounceFetchAvailable = useDebounceFn(
      async (profileId, addressId, appointmentReasonIds, selectedRemote) => {
        await httpClient
          .get(
            `/appointment/available?profiles=${profileId}&addresses=${addressId}&start=${start.value.toISOString()}&end=${end.value.toISOString()}&appointmentReasons=${appointmentReasonIds}&remote=${selectedRemote === 'remote'}&presential=${selectedRemote === 'in-person'}`,
          )
          .then(async (response: ApiResponse<AvailableData>) => {
            await handleAppointmentAvailableResponse(
              response.data,
              profileId,
              appointmentReasonIds,
            )
          })
          .catch(error => {
            console.error('Error fetching appointment slots:', error)
            MessageService.error(t('common.error.errorHasOccurred'))
          })
          .finally(() => {
            loading.value = false
          })
      },
      500,
    )

    // fetch on any change in address, motive, or remote type
    watch(
      [selectedAddress, selectedReason, remote],
      async (
        [newAddr, newReason, newRemote],
        [oldAddr, oldReason, oldRemote],
      ) => {
        const practitionerPublicProfileStore =
          usePractitionerPublicProfileStore()
        const profileId = practitionerPublicProfileStore.profile?.id
        if (!profileId) return
        loading.value = true
        const appointmentReason = newReason ?? oldReason
        const selectedRemote = newRemote ?? oldRemote
        const addr = newAddr ?? oldAddr

        let addressId = ''
        if (addr && addr.id) {
          addressId = addr.id
        }
        let appointmentReasonId = ''
        if (appointmentReason && appointmentReason.id) {
          appointmentReasonId = appointmentReason.id
        }
        if (!appointmentReasonId) return
        await debounceFetchAvailable(
          profileId,
          addressId,
          appointmentReason.id,
          selectedRemote,
        )
      },
      { immediate: false },
    )

    const loading = ref<boolean>(false)

    const noAvailability = ref<false | string>(false) // values : string with message or false
    const nextAvailability = ref<string>(null) // string of the next availability
    const nextAvailabilityDate = ref<dayjs.Dayjs>(null)

    const setNextAvailabiility = (
      nextAvailabilityString: string,
      dateWrapped: string,
    ) => {
      nextAvailability.value = nextAvailabilityString
      meetingsDays.value = placeholderMeetingsDaysEmptyGenerator(
        start.value,
        numberOfDaysDisplayed.value,
      )
      noAvailability.value = false
      nextAvailabilityDate.value = dayjs(dateWrapped)
    }

    const setNoAvailabilityMessage = (message: string) => {
      noAvailability.value = message
      meetingsDays.value = placeholderMeetingsDaysEmptyGenerator(
        start.value,
        numberOfDaysDisplayed.value,
      )
      nextAvailability.value = ''
      nextAvailabilityDate.value = null
    }

    const setAvailabilities = data => {
      nextAvailability.value = ''
      noAvailability.value = false
      meetingsDays.value = data
      nextAvailabilityDate.value = null
    }

    const handleAppointmentAvailableResponse = async (
      data: AvailableData | IDateWrapper | IMessageWrapper,
      profileId: string,
      formattedReasons: string,
    ) => {
      const hasNextAvailability = Object.prototype.hasOwnProperty.call(
        data,
        'date',
      )
      const hasNoAvailability = Object.prototype.hasOwnProperty.call(
        data,
        'message',
      )
      if (hasNextAvailability) {
        // case there is a next appointment
        const nextDate = (data as IDateWrapper).date
        setNextAvailabiility(dayjs(nextDate).format('ddd D MMM'), nextDate)
      } else if (hasNoAvailability) {
        // case no availability
        setNoAvailabilityMessage((data as IMessageWrapper).message)
      } else {
        // case we have the availabilities
        const tmpData = transformDataFromAvailableData(data as AvailableData)
        const finalMeetingsDay = addMissingDays(
          tmpData,
          start.value,
          numberOfDaysDisplayed.value,
        )
        setAvailabilities(finalMeetingsDay)
      }
    }

    /**
     * If no arguments are given, this use the practitioner profile storem else all should be given
     * @param profileId
     * @param ownerId - practitioner id
     * @returns
     */
    const fetchAppointmentSlots = async (
      profileId: string = '',
      ownerId: string = '',
    ) => {
      const practitionerPublicProfileStore = usePractitionerPublicProfileStore()

      if (!practitionerPublicProfileStore.profile && !profileId) return
      profileId = practitionerPublicProfileStore.profile?.id || profileId

      if (!remote.value || !selectedReason.value || !start.value || !end.value)
        return

      if (0 === motives.value.length) {
        await fetchMotives(profileId, ownerId)
      }

      const formattedReasons = selectedReason.value.id
      loading.value = true
      await debounceFetchAvailable(
        profileId,
        selectedAddress.value ? selectedAddress.value.id : '',
        formattedReasons,
        remote.value,
      )
    }

    // note that 'motive' and 'appointmentReason' are the same thing, only the name change
    const motives = ref<FormattedAppointmentReasonInformationData[]>([])
    const fetchMotives = async (
      profileId: string = '',
      ownerId: string = '',
    ) => {
      const practitionerPublicProfileStore = usePractitionerPublicProfileStore()
      const profile = practitionerPublicProfileStore.profile

      if (profile && !profile.owner && !ownerId) return
      profileId = profileId ? profileId : profile.id
      ownerId = profile.owner.id ? profile.owner.id : ownerId

      await httpClient
        .get<AppointmentReasonInformationData[]>(
          `/appointment-reason/practician/${profileId}`,
        )
        .then(response => {
          motives.value = response.data
            .filter(motive =>
              profile && motive.profile
                ? motive.profile.id === profileId
                : false,
            )
            .map(motive => ({
              ...motive,
              remote: motive.remote ? 'yes' : 'no',
            }))

          if (selectedReasonId.value) {
            selectedReason.value = motives.value.find(
              motive => motive.id === selectedReasonId.value,
            )
          }

          if (!selectedReason.value) {
            selectedReason.value = motives.value[0]
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error)
        })
    }

    const createTemporaryAppointment = async (date: Date) => {
      const payload: AppointmentTemporaryPayload = {
        address: selectedAddress.value.id,
        appointmentReason: selectedReason.value.id,
        comment: null,
        dateTime: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
        presential: remote.value == 'in-person',
        remote: remote.value == 'remote',
        timezone: dayjs.tz.guess(),
      }
      LoadingBackdropService.start()

      await httpClient
        .post<IAppointmentTemporary>(
          `/appointment-patient/temporary`,
          payload,
          { headers: { 'Content-Type': 'application/json' } },
        )
        .then(async response => {
          temporaryAppointment.value = response.data

          // start absence timer and register route params
          const absenceServiceStore = useAbsenceServiceStore()
          absenceServiceStore.publicProfileParams = {
            discipline: router.currentRoute.value.params.discipline as string,
            city: router.currentRoute.value.params.city as string,
            practitionerSlug: router.currentRoute.value.params
              .practitionerSlug as string,
          }
          absenceServiceStore.startTimer()

          await router.push({
            name: 'ScheduleAppointment',
            params: { id: temporaryAppointment.value.id },
          })
          // TODO test this case
          if (response.status === 422) {
            MessageService.error(
              t('practitionerPublicProfile.errorNoAppointmentAvailable'),
            )
            await fetchAppointmentSlots()
          }
        })
        .catch(async error => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            MessageService.error(error.response.data.message)
            await fetchAppointmentSlots()
          } else {
            MessageService.error(t('common.error.errorHasOccurred'))
          }
          console.error('Error creating temporary appointment data:', error)
        })
      LoadingBackdropService.stop()
    }

    return {
      selectedMeeting,
      date,
      start,
      end,
      numberOfDaysDisplayed,
      remote,
      loading,
      selectedAddress,
      selectedReason,
      selectedReasonId,
      meetingsDays,
      fetchAppointmentSlots,
      motives,
      fetchMotives,
      temporaryAppointment,
      createTemporaryAppointment,

      noAvailability,
      nextAvailability,
      nextAvailabilityDate,
      setNextAvailabiility,
      setNoAvailabilityMessage,
      setAvailabilities,
    }
  },
)
