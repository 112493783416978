import { nextTick, Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import createHttpClient from '@/api/httpClient'

import { ConsultationInformationData } from '@/api/confirmation_consultation_absence/confirmation_consultation.d'
import { ApiResponse } from '@/api/api'
import avatarPng from '@/assets/avatar.png'
import EventBus from '@/utils/eventBus'
import { useUserStore } from '../user/userStore'
import LoadingBackdropService from '@/components/feedback/loadingBackdrop/loadingBackdropService'
import { AskPatientPresenceToPractitioner } from './askPatientPresenceToPractitioner'
import dayjs from 'dayjs'
import { formatTime } from '@/utils/formatTime'
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter'
const httpClient = createHttpClient()

export const useAskPatientPresenceToPractitionerStore = defineStore(
  'askPractitionerConsultationStore',
  () => {
    const consultations = ref<AskPatientPresenceToPractitioner[]>([
      // {
      //   id: 1,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 2,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 3,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 4,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 5,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 6,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 7,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 8,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 9,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 10,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
    ])
    const allConsultationForAbsence: Ref<ConsultationInformationData[]> = ref(
      [],
    )
    const fetchConsultations = async () => {
      const userStore = useUserStore()
      if (!userStore.isLogged) return
      try {
        const res: ApiResponse<ConsultationInformationData[]> =
          await httpClient.get('/appointment-notification/practician')

        if (res.status != 200) throw Error(JSON.stringify(res.data))

        consultations.value = res.data.map(consultation => {
          const tmpconsultation: AskPatientPresenceToPractitioner = {
            id: consultation.id,
            appointmentReason: consultation.appointmentReason,
            customerName: consultation.customerName,
            date: capitalizeFirstLetter(
              dayjs(consultation.date).format('ddd D MMM'),
            ),
            duration: formatTime('' + consultation.duration),
            price: '' + consultation.price + ' €',
            time: dayjs(consultation.date).format('HH:mm').replace(':', 'h'),
            note: consultation.comment,
            photo: consultation.customerPicture,
          }
          return tmpconsultation
        })

        if (0 < consultations.value.length) {
          EventBus.emit('open-practitioner-ask-presence-dialog')
        }
      } catch (error) {
        console.error(error)
      } finally {
        if (!consultations.value || !consultations.value.length) {
          EventBus.emit('close-practitioner-ask-presence-dialog')
        }
      }
    }
    const sendDataForAbsence = async (uuid: string) => {
      const indexItem = consultations.value.findIndex(item => item.id === uuid)
      consultations.value.splice(indexItem, 1)
      // try {
      //   const res: ApiResponse<ConfirmationConsultationInformationData[]> =
      //     await httpClient.post(`/opinion/absence-confirm/${uuid}`)
      //   if (res.status != 200) throw Error(JSON.stringify(res.data))
      // } catch (error) {
      //   console.error(error)
      // }
    }

    const contesterForAbsence = async (
      uuid: string,
      payload: { commentPatient: string },
    ) => {
      const indexItem = consultations.value.findIndex(item => item.id === uuid)
      consultations.value.splice(indexItem, 1)
      try {
        const res: ApiResponse<[]> = await httpClient.post(
          `/opinion/absence-protest/${uuid}`,
          payload,
        )
        if (res.status != 200) throw Error(JSON.stringify(res.data))
      } catch (error) {
        console.error(error)
      }
    }

    async function startLoop() {
      // Exécute le fetch immédiatement
      await fetchConsultations()

      // Puis exécute let fetch toutes les 5 minutes
      setInterval(fetchConsultations, 5 * 60 * 1000)
    }

    return {
      fetchConsultations,
      consultations,
      startLoop,

      allConsultationForAbsence,
      sendDataForAbsence,
      contesterForAbsence,
    }
  },
)
