import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'
import { TvaResponse } from '@/api/tva/tva.d'
import { ApiResponse } from '@/api/api'
import { fetchUserTva } from '@/api/tva/tva'

export const useUserTvaStore = defineStore('usertva', () => {
  const countryTva: Ref<TvaResponse> = ref()
  const discountPercent = ref<number>(25)
  const loadTva = ref(false)

  const getUserTva = async () => {
    if (loadTva.value) return false
    loadTva.value = true
    try {
      const res: ApiResponse<TvaResponse> = await fetchUserTva()
      if (res.status !== 200) throw Error(JSON.stringify(res))

      countryTva.value = { ...res.data }
    } catch {
    } finally {
      loadTva.value = false
    }
  }

  const applyTva = (
    price: number,
    tvaRate: number,
    discountPercent: number = 25,
    applyDiscount: boolean = true,
  ) => {
    const discount = (price * tvaRate) / 100
    const amount = price + discount
    const amountTest = (amount * discountPercent) / 100
    if (applyDiscount) {
      const theprice = amount - (amount * discountPercent) / 100
      return Math.round(theprice * 100) / 100
    } else {
      return Math.round(amount * 100) / 100
    }
  }

  // const updateTva = (v: number) => {
  //   countryTva.value = { ...countryTva.value, tva: v }
  // }

  return {
    getUserTva,
    countryTva,
    applyTva,
    loadTva,
  }
})
