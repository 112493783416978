<template>
  <v-dialog v-model="modal" max-width="500">
    <template v-slot:default="{ isActive }">
      <v-card
        :title="
          modalType === ModalWarningType.WARNING
            ? $t('dashboard.addresses.warning')
            : $t('dashboard.addresses.confirmation')
        "
      >
        <v-card-text class="d-flex flex-column ga-2">
          {{ modalText }}
          <template v-if="modalType === ModalWarningType.DELETE">
            <v-chip
              v-for="(address, i) in filteredAddresses"
              :key="address.addressLine"
              color="secondary"
              class="flex-grow-1 rounded-lg cursor-pointer d-flex justify-space-between"
              :append-icon="
                migrationAddressIndex == i ? 'mdi-check-outline' : ''
              "
              @click="migrationAddressIndex = i"
            >
              <div class="address-text-wrapper">
                {{ truncateText(formatAddress(address, true), 60) }}
              </div>
            </v-chip>
          </template>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :text="
              modalType == ModalWarningType.DELETE
                ? $t('dashboard.addresses.delete')
                : $t('dashboard.addresses.continue')
            "
            :color="modalType == ModalWarningType.DELETE ? 'red' : 'secondary'"
            rounded="lg"
            variant="outlined"
            :loading="dialogActionLoading"
            @click="
              modalType === ModalWarningType.DELETE
                ? handleDelete(selectedAdressIndex)
                : handleUpdateAddresses()
            "
          ></v-btn>
          <v-btn
            rounded="lg"
            variant="outlined"
            color="black"
            :text="$t('dashboard.addresses.cancel')"
            @click="isActive.value = false"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
  <form-block
    :title="$t('dashboard.addresses.title')"
    :loading="fillAddressesLoading"
  >
    <h3 class="my-10 text-center align-self-center text-grey">
      {{ $t('dashboard.addresses.description') }}
    </h3>
    <div class="d-flex flex-column text-center ga-3 w-auto mx-auto">
      <p v-if="addressList.length">
        {{ $t('dashboard.addresses.registeredAddresses') }}
      </p>
      <p v-else-if="!fillAddressesLoading" class="text-center">
        {{ $t('dashboard.addresses.noAddress') }}
      </p>

      <v-slide-x-reverse-transition
        v-for="(address, i) in addressList"
        :key="address.addressLine"
      >
        <v-row
          class="ga-4 justify-space-between align-center flex-nowrap rows-addresses"
          transition="scroll-x-reverse-transition"
          no-gutters
        >
          <v-chip
            append-icon="mdi-square-edit-outline"
            color="secondary"
            class="flex-grow-1 rounded-lg cursor-pointer d-flex justify-space-between"
            @click="switchEditMode(address, i)"
          >
            <div class="text-primary address-text-wrapper">
              {{ truncateText(formatAddress(address, true), 60) }}
            </div>
          </v-chip>
          <v-tooltip
            v-if="addressList.length === 1"
            location="top"
            :text="$t('dashboard.addresses.tooltipMsg')"
          >
            <template v-slot:activator="{ props }">
              <div v-bind="props">
                <v-btn
                  v-bind="props.attrs"
                  v-on="props.on"
                  :disabled="addressList.length == 1"
                  variant="flat"
                  color="red"
                  density="compact"
                  icon="mdi-close"
                  @click="confirm(i, ModalWarningType.DELETE)"
                >
                </v-btn>
              </div>
            </template>
          </v-tooltip>
          <v-btn
            v-else
            variant="flat"
            color="red"
            density="compact"
            icon="mdi-close"
            @click="confirm(i, ModalWarningType.DELETE)"
          >
          </v-btn>
        </v-row>
      </v-slide-x-reverse-transition>
      <v-row
        v-if="showForm || addressList.length < 3"
        class="ga-2 justify-end mb-6"
        no-gutters
      >
        <v-btn
          :variant="!showForm ? 'flat' : 'outlined'"
          color="secondary"
          width="150"
          size="large"
          @click="handleAddOrCancelBtn(!showForm ? 'add' : 'cancel')"
        >
          {{
            !showForm
              ? $t('dashboard.addresses.add')
              : $t('dashboard.addresses.cancel')
          }}
        </v-btn>
      </v-row>
      <v-expand-transition>
        <div v-show="showForm">
          <v-form
            ref="form"
            @submit.prevent
            validate-on="submit"
            class="d-flex flex-column ga-4"
          >
            <v-switch
              v-model="onlyRemote"
              :label="$t('dashboard.addresses.useOnlyRemote')"
              color="blue"
              hide-details
            />
            <v-autocomplete
              v-model="country"
              :items="Object.keys(countries)"
              :label="$t('dashboard.addresses.selectCountry') + '*'"
              :rules="mandatoryRules"
              clearable
              hide-details
              validate-on="lazy"
            />
            <v-autocomplete
              v-model="addressLine"
              :label="$t('dashboard.addresses.address') + '*'"
              :rules="mandatoryRules"
              :items="autocompleteAddressList"
              :auto-select-first="false"
              :custom-filter="() => true"
              clearable
              item-title="label"
              item-value="value"
              return-object
              hide-details
              validate-on="lazy"
              autocomplete="new-input"
              @input="onInputAddress"
              @update:search="autocompleteRequest"
              @update:model-value="handleSelectAutocomplete($event)"
            >
              <template v-slot:append-inner="{ isActive }">
                <v-btn
                  v-show="loadingAddresses && isActive"
                  :loading="loadingAddresses"
                  variant="text"
                  icon="mdi-loading"
                  disabled
                >
                </v-btn>
              </template>
            </v-autocomplete>
            <div class="d-flex ga-4 ga-sm-2" :class="{ 'flex-column': xs }">
              <v-text-field
                v-model="postalCode"
                :rules="mandatoryRules"
                hide-details
                clearable
                validate-on="lazy"
                :label="$t('dashboard.addresses.postalCode') + '*'"
              />
              <v-text-field
                v-model="city"
                hide-details
                :rules="mandatoryRules"
                clearable
                validate-on="lazy"
                :label="$t('dashboard.addresses.city') + '*'"
              />
            </div>
            <rich-editor
              v-model:content="additionalInstructions"
              :key="additionalInstructionsKey"
              contentType="html"
              class="rich-text-field"
              :label="$t('dashboard.addresses.addtionnalInfo')"
              :counter="500"
            >
            </rich-editor>
            <p v-if="showForm" class="text-end text-grey font-italic">
              {{ $t('dashboard.addresses.asteriskForMandatoryFields') }}
            </p>
            <v-btn
              class="mt-8 text-uppercase align-self-center"
              width="350"
              color="accent"
              size="large"
              :loading="addAddressLoading"
              @click="
                formMode === FormMode.EDIT
                  ? confirm(selectedAdressIndex, ModalWarningType.EDIT)
                  : handleUpdateAddresses()
              "
            >
              {{ $t('dashboard.addresses.updateAddresses') }}
            </v-btn>
          </v-form>
        </div>
      </v-expand-transition>
    </div>
  </form-block>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, Ref, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { VForm } from 'vuetify/lib/components/index.mjs'
import {
  deleteAddress,
  getAddresses,
  updateAddresses,
  checkAppointment,
} from '@/api/account/account'
import {
  AddressInformationData,
  AddressUpdateParams,
} from '@/api/account/address'
import countries from '../../../shared/5-countries-dom-tom-dic.json'
import { GeocodeEarthParams } from '@/api/utils/geocodeearth'
import { formatReceivedAddresses } from './addressUtils'
import FormBlock from '@/components/common/FormBlock.vue'
import { formatAddress } from '@/utils/formatAddress'
import { useDisplay } from 'vuetify/lib/framework.mjs'
import {
  getAlpha2CountryCodeWithDOMTOM,
  getAlpha3CountryCode,
} from '@/utils/getCountryCode'
import LoadingBackdropService from '@/components/feedback/loadingBackdrop/loadingBackdropService'
import { useDashboardStatus } from '@/store/dashboard-status/dashboardStatus'
import { useGoogleMapsStore } from '@/store/google-maps/googleMapsStore'
import { truncateText } from '@/utils/truncate'
import MessageService from '@/components/feedback/message/messageService'
import RichEditor from '@/components/inputs/RichEditor.vue'

const googleMapsStore = useGoogleMapsStore()

interface AutocompleteAddress {
  label: string
  value: AddressItem
}

interface AddressItem {
  country: string | null
  addressLine: string | null
  postalCode: string | null
  city: string | null
  latitude?: string
  longitude?: string
  additionalInstructions?: string | null
  remote?: boolean
}

interface AddressLine {
  label: string
  value: AddressItem
}
enum FormMode {
  HIDDEN = 'hidden',
  ADD = 'add',
  EDIT = 'edit',
}

const dashboardState = useDashboardStatus()

const $t = useI18n().t

const { xs } = useDisplay()

const mandatoryRules = [
  value => !!value || $t('dashboard.addresses.requiredField'),
]

const addressList: Ref<AddressInformationData[]> = ref([])

const form: Ref<VForm> = ref(null)
const formMode: Ref<FormMode> = ref(FormMode.HIDDEN)
const showForm = ref(false)

const onlyRemote = ref<boolean>(false)

const country = ref('')
const addressLine: Ref<AddressLine> = ref({ label: '', value: null })
const postalCode = ref('')
const city = ref('')
const additionalInstructions = ref(null)
const additionalInstructionsKey = ref(0)
const latitude = ref('')
const longitude = ref('')

const autocompleteAddressList: Ref<AutocompleteAddress[]> = ref([])

const selectedAdressIndex = ref(null)

const filteredAddresses = computed(() =>
  addressList.value.filter((a, i) => i != selectedAdressIndex.value),
)

const dialogActionLoading = ref(false)
const setBtnDialogLoading = (isLoading: boolean) => {
  dialogActionLoading.value = isLoading
}

const loadingAddresses = ref(false)

const migrationAddressIndex = ref(0)

enum ModalWarningType {
  DELETE = 'delete',
  EDIT = 'edit',
  NONE = '',
  WARNING = 'warning',
}

const modal = ref(false)

const modalType: Ref<ModalWarningType> = ref(ModalWarningType.NONE)

const modalText = computed(() => {
  if (modalType.value == ModalWarningType.EDIT) {
    return $t('dashboard.addresses.alertOnUpdate')
  } else if (modalType.value == ModalWarningType.DELETE) {
    return $t('dashboard.addresses.alertOnDelete', addressList.value.length)
  } else if (modalType.value == ModalWarningType.WARNING) {
    return $t('dashboard.addresses.alertOnWarning')
  }
  return ''
})

const openModal = () => {
  modal.value = true
}

const closeModal = () => {
  modal.value = false
}

const confirm = async (i: number, type: ModalWarningType) => {
  if (
    !form.value.isValid &&
    type !== ModalWarningType.DELETE &&
    type !== ModalWarningType.WARNING
  ) {
    return
  }

  modalType.value = type
  selectedAdressIndex.value = i

  if (ModalWarningType.DELETE === type) {
    const { status, data } = await checkAppointment(
      addressList.value[selectedAdressIndex.value].id,
    )

    let hasPatient = data
    if (hasPatient) openModal()
    else await handleDelete(selectedAdressIndex.value)
  } else {
    openModal()
  }
}

const handleCheckAppointment = async index => {
  closeModal()
  const { status, data } = await checkAppointment(addressList.value[index].id)
  let hasPatient = data
  if (status !== 200) throw Error('Error occurred on get addresses')
  if (hasPatient) confirm(index, ModalWarningType.WARNING)
  else handleDelete(index)
  setBtnDialogLoading(false)
}

const handleDelete = async index => {
  setBtnDialogLoading(true)
  try {
    await deleteAddress(addressList.value[index].id, {
      newAddress: filteredAddresses.value[migrationAddressIndex.value].id,
    })
    await fillAddresses()

    MessageService.success($t('dashboard.addresses.successDelete'))

    // update dashboard status
    dashboardState.fetchDashboardStatus()
  } catch (e) {
    console.error(e)
  }
  setBtnDialogLoading(false)
  closeModal()
}

watch(country, (newCountry, oldCountry) => {
  if (newCountry !== oldCountry) {
    autocompleteAddressList.value = []
  }
})

/** Old function used with geocode earth. We keep it so we can rollback just in case */
const generateAutocompleteParams = (text: string): GeocodeEarthParams => {
  const params = {
    text,
    layers: 'address',
  }

  const countryCode = getAlpha3CountryCode(country.value)
  if (country.value && countryCode) {
    params['boundary.country'] = countryCode
  }

  /* TODO open this when API ready and if we want to have btter performances on the geo search
  if (latitude.value && longitude.value) {
    params['focus.point.lat'] = latitude.value
    params['focus.point.lon'] = longitude.value
  }
  */

  return params
}

const resetFields = () => {
  country.value = ''
  addressLine.value = { label: '', value: null }
  postalCode.value = ''
  city.value = ''
  additionalInstructions.value = null
  additionalInstructionsKey.value++
}

const handleAddOrCancelBtn = mode => {
  addressLine.value = { label: '', value: null }
  form.value.reset()
  if (mode == 'add') {
    resetFields()
    formMode.value = FormMode.ADD
  } else if (mode == 'cancel') {
    formMode.value = FormMode.HIDDEN
  }
  showForm.value = !showForm.value
  selectedAdressIndex.value = null
}

const onInputAddress = () => {
  loadingAddresses.value = true
}

const autocompleteRequest = useDebounceFn(async text => {
  if (!text) {
    loadingAddresses.value = false
    return
  }
  try {
    const placesSuggested =
      await googleMapsStore.getAutocompleteSuggestionsPlaces(
        text,
        country.value ? [getAlpha2CountryCodeWithDOMTOM(country.value)] : [],
      )
    autocompleteAddressList.value = placesSuggested
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
  loadingAddresses.value = false
}, 500)
/** Old geocodeearth code autocomplete
const autocompleteRequest = useDebounceFn(async text => {
  if (!text) {
    loadingAddresses.value = false
    return
  }
  try {
    const res = await getGeocodeEarthFeatures(generateAutocompleteParams(text))
    const { data } = res
    const extractedValues: AutocompleteAddress[] = data.features.map(f => {
      return {
        label: f.properties.label,
        value: {
          country: f.properties.country,
          addressLine: f.properties.name,
          postalCode: f.properties.postalcode,
          city: f.properties.locality,
          latitude: f.geometry?.coordinates[0]?.toString(),
          longitude: f.geometry?.coordinates[1]?.toString(),
        },
      }
    })
    autocompleteAddressList.value = extractedValues // Update the items ref with the response data
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
  loadingAddresses.value = false
}, 500)
*/

const switchEditMode = (addressItem: AddressInformationData, i) => {
  showForm.value = true
  country.value = addressItem.country
  postalCode.value = addressItem.postalCode
  city.value = addressItem.city
  additionalInstructions.value = addressItem.additionalInstructions
  additionalInstructionsKey.value++
  latitude.value = addressItem.latitude
  longitude.value = addressItem.longitude
  onlyRemote.value = addressItem.remote ?? false
  addressLine.value = {
    label: addressItem.addressLine,
    value: {
      addressLine: addressItem.addressLine,
      country: country.value,
      postalCode: postalCode.value,
      city: city.value,
      additionalInstructions: additionalInstructions.value,
      latitude: latitude.value,
      longitude: longitude.value,
      remote: onlyRemote.value,
    },
  }

  formMode.value = FormMode.EDIT
  selectedAdressIndex.value = i
}

/**
 * Fill the form on click
 * label : string
 * value : suggestion
 * */
const handleSelectAutocomplete = async (addressItem: {
  label: string
  value: any
}) => {
  addressItem.value = await googleMapsStore.getFormatedAddressFromPlace(
    addressItem.value,
  )

  country.value = addressItem.value.country
  postalCode.value = addressItem.value.postalCode
  city.value = addressItem.value.city

  latitude.value = addressItem.value.latitude
  longitude.value = addressItem.value.longitude
  addressLine.value = {
    label: addressItem.value.addressLine,
    value: {
      addressLine: addressItem.value.addressLine,
      country: country.value,
      postalCode: postalCode.value,
      city: city.value,
      additionalInstructions: additionalInstructions.value,
      latitude: latitude.value,
      longitude: longitude.value,
    },
  }
}

const addAddressLoading = ref(false)

const handleUpdateAddresses = async () => {
  setBtnDialogLoading(true)
  form.value.validate()
  if (!form.value.isValid) {
    return
  }

  const updatedList: AddressUpdateParams[] = JSON.parse(
    JSON.stringify(addressList.value),
  ).map(address => {
    const addr = { ...address }
    if (!Object.prototype.hasOwnProperty.call(addr, 'remote')) {
      addr.remote = false
    }
    return addr
  })
  const currentFormItem: AddressUpdateParams = {
    additionalInstructions: additionalInstructions.value
      ? additionalInstructions.value
      : null,
    addressLine: addressLine?.value?.label,
    city: city.value,
    country: country.value,
    postalCode: postalCode.value,
    latitude: latitude.value,
    longitude: longitude.value,
    remote: onlyRemote.value,
  }

  if (formMode.value == FormMode.ADD) {
    addAddressLoading.value = true
    updatedList.push(currentFormItem)
  } else if (formMode.value == FormMode.EDIT) {
    const { id } = addressList.value[selectedAdressIndex.value]
    currentFormItem.id = id
    updatedList.splice(selectedAdressIndex.value, 1, currentFormItem)
  }

  try {
    const res = await updateAddresses(updatedList)
    if (res.status == 200) {
      addressList.value = formatReceivedAddresses(res.data)
      resetFields()
      showForm.value = false
      MessageService.success(
        $t('dashboard.addresses.successUpdate', {
          count: addressList.value.length,
        }),
      )
    }

    if (formMode.value == FormMode.ADD) {
      // update dashboard status
      dashboardState.fetchDashboardStatus()
    }
  } catch (e) {
    MessageService.error($t('dashboard.addresses.errorUpdate'))
    console.error(e)
  }
  addAddressLoading.value = false
  setBtnDialogLoading(false)
  closeModal()
}

const fillGeolocation = () => {
  navigator.geolocation.getCurrentPosition(
    position => {
      latitude.value = position.coords.latitude.toString()
      longitude.value = position.coords.longitude.toString()
    },
    error => {
      console.error('Error getting location:', error)
    },
  )
}

const fillAddressesLoading = ref(false)
const fillAddresses = async () => {
  fillAddressesLoading.value = true
  const { status, data } = await getAddresses()
  fillAddressesLoading.value = false
  if (status !== 200) throw Error('Error occurred on get addresses')
  addressList.value = formatReceivedAddresses(data)
}

onMounted(async () => {
  LoadingBackdropService.start()
  fillGeolocation()
  await fillAddresses()
  LoadingBackdropService.stop()
})
</script>

<style lang="scss" scoped>
.address-text-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; // Ensure that the text does not exceed the parent width
  // Media queries to adjust truncation at different screen widths

  @media screen and (max-width: 1024px) and (min-width: 769px) {
    max-width: 25rem;
  }

  @media screen and (max-width: 768px) and (min-width: 577px) {
    max-width: 20rem;
  }

  @media screen and (max-width: 576px) and (min-width: 350px) {
    max-width: 11rem;
  }

  @media screen and (max-width: 349px) and (min-width: 321px) {
    max-width: 9rem;
    font-size: 10px;
  }

  @media screen and (max-width: 576px) and (min-width: 350px) {
    max-width: 11rem;
  }

  @media screen and (max-width: 349px) and (min-width: 321px) {
    max-width: 9rem;
    font-size: 10px;
  }
  @media screen and (max-width: 320px) {
    max-width: 9rem;
    font-size: 11px;
  }
}

.rich-text-field > div {
  text-align: left;
}
.bg-accent.glow {
  box-shadow: 0 0 20px 0px rgb(var(--v-theme-accent));
}
h3 {
  max-width: 450px;
  margin: auto;
}
// Fix on mobile size rounded fields
@media screen and (max-width: 630px) {
  :deep() {
    .v-field__outline__notch {
      max-width: 60%;
    }
  }
}
</style>
