import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from 'axios'
import { useUserStore } from '@/store/user/userStore'
import MessageService from '@/components/feedback/message/messageService'
import i18n from '@/plugins/i18n'

const { t } = i18n.global

window.addEventListener('unhandledrejection', event => {
  if (event.reason && event.reason.isAxiosError) {
    MessageService.error(t('common.error.errorHasOccurred'))
  }
})

const createHttpClient = (baseURL?: string): AxiosInstance => {
  baseURL = baseURL
    ? import.meta.env.VITE_API_URL + baseURL
    : import.meta.env.VITE_API_URL

  const client = axios.create({
    baseURL,
    timeout: 100000,
  })

  client.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const userStore = useUserStore()
      const access_token = userStore.user?.token
      if (access_token && config.url !== '/refresh-token') {
        const authorizationHeader = `Bearer ${access_token}`
        config.headers.set('Authorization', authorizationHeader)
      }
      if (config.method === 'post' && !config.headers.has('Content-Type')) {
        config.headers.set('Content-Type', 'multipart/form-data')
      }
      return config
    },
    (error: AxiosError) => {
      return Promise.reject(error)
    },
  )

  client.interceptors.response.use(
    response => {
      return response
    },
    async error => {
      if (
        401 === error.response?.status &&
        !['/refresh-token', '/login-check', '/auth/temp-token'].includes(
          error.config.url || '',
        )
      ) {
        const userStore = useUserStore()
        await userStore.refreshToken()
        // redo the request
        return client.request(error.config)
      }
      return Promise.reject(error)
    },
  )

  return client
}

export default createHttpClient
