<template>
  <v-dialog
    :model-value="dialogStore.isDialogTimeSlotOpen"
    persistent
    class="timeSlotDialogModal"
    width="fit-content"
    transition="dialog-top-transition"
  >
    <v-card class="bg-white">
      <v-card-title
        class="d-flex pa-4 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span>{{
          dialogStore.isEditing
            ? $t('dashboard.time-slot.dialogTimeSlot.editTimeSlot')
            : $t('dashboard.time-slot.createTimeSlot')
        }}</span>
        <v-btn
          class="close-icon mr-2"
          variant="text"
          icon="mdi-close"
          @click="dialogStore.toggleDialogTimeSlot"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="d-flex flex-column ga-2 pa-2 pa-sm-6">
        <div class="d-flex align-center justify-center">
          <SelectProfileAndAddress @update:profile="clearMotives" />
        </div>

        <div class="d-flex align-center justify-center ga-1 pl-5">
          <SelectDateAndTime
            v-model:date="dialogStore.selectedDate"
            v-model:start="dialogStore.selectedStartTime"
            v-model:end="dialogStore.selectedEndTime"
            @update:start="onUpdateStartTime"
          />
        </div>
        <div class="d-flex align-center ga-4 px-5 my-2">
          <v-icon icon="mdi-account-multiple-outline" />
          <v-select
            v-model="dialogStore.selectedAppointmentReasons"
            :items="
              appointmentReasonStore.appointmentReasons.filter(
                ap => ap.profile.id === dialogStore.selectedProfile?.id,
              )
            "
            class="select-reason"
            clearable
            :error-messages="timeSlotStore.errors"
            rounded
            multiple
            density="compact"
            item-title="label"
            item-value="id"
            return-object
            :placeholder="
              $t('dashboard.time-slot.dialogTimeSlot.placeholderSelectMotive')
            "
            @update:model-value="validateIsRemote($event)"
          >
            <template #item="{ item, props }">
              <v-list-item v-bind="props">
                <div class="d-flex flex-row justify-between">
                  <span class="flex-shrink-1" :class="{ 'fs-10': xs }">{{
                    item.value.label
                  }}</span>
                  <span class="text-caption">{{
                    $t('dashboard.time-slot.dialogTimeSlot.duration', {
                      duration: String(JSON.stringify(item.raw.duration)),
                    })
                  }}</span>
                </div>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item, index }">
              <v-chip rounded v-if="index <= 1">{{ item.title }}</v-chip>

              <span
                v-if="index == 2"
                class="text-grey text-caption align-self-center"
              >
                {{
                  $t('dashboard.time-slot.dialogTimeSlot.plusXOther', {
                    n: dialogStore.selectedAppointmentReasons.length - 2,
                  })
                }}
              </span>
            </template>
          </v-select>
        </div>

        <div
          class="mx-auto text-decoration-underline text-center text-blue-darken-1 cursor-pointer"
          :class="{ 'text-custom-in-mobile': xs }"
          @click="toggleCreateMotiveDialog"
        >
          {{ $t('dashboard.time-slot.createMotive') }}
        </div>
        <DialogMotive
          v-model="showCreateMotiveDialog"
          :default-selected-profile-id="dialogStore.selectedProfile?.id"
        />

        <v-divider class="mt-2"></v-divider>
        <p class="mt-3 font-bold text-center">
          {{ $t('dashboard.time-slot.IAcceptRendezVous') }}
        </p>
        <v-radio-group
          class="mx-auto ga-2 flex radio-group-custom"
          inline
          hide-details
          v-model="dialogStore.acceptRemote"
        >
          <v-radio
            v-if="!dialogStore.selectedAddress.remote"
            color="blue"
            :label="$t('dashboard.time-slot.in-person')"
            value="in-person"
          ></v-radio>
          <v-radio
            color="blue"
            :label="$t('dashboard.time-slot.remote')"
            value="remote"
          ></v-radio>
          <v-radio
            v-if="!dialogStore.selectedAddress.remote"
            color="blue"
            value="both"
            :label="$t('dashboard.time-slot.theTwo')"
          ></v-radio>
        </v-radio-group>
        <v-divider class="my-2"></v-divider>
        <div
          v-if="!dialogStore.isEditing"
          class="d-flex justify-center align-center ga-4"
        >
          <span>{{ $t('dashboard.time-slot.doYouWantToRepeatTimeSlot') }}</span>
          <v-radio-group
            class="d-flex flex-shrink-1 flex-grow-0"
            v-model="dialogStore.repeatTimeSlot"
            inline
            hide-details
          >
            <v-radio
              color="blue"
              :label="$t('dashboard.time-slot.yes')"
              value="yes"
            ></v-radio>
            <v-radio
              color="blue"
              :label="$t('dashboard.time-slot.no')"
              value="no"
            ></v-radio>
          </v-radio-group>
        </div>
        <div
          v-if="dialogStore.repeatTimeSlot === 'yes'"
          class="d-flex align-center justify-center flex-column ga-4"
        >
          <div class="d-flex align-center ga-4">
            <span>{{ $t('dashboard.time-slot.every') }}</span>
            <VTextField
              v-model="dialogStore.everyXWeek"
              hide-details
              density="compact"
              class="flex-grow-0 input-every-x-week"
              :rules="[numberRule]"
              size="xs"
            />
            <span>{{ $t('dashboard.time-slot.weekThe') }}</span>
          </div>

          <v-btn-toggle
            v-model="dialogStore.selectedDays"
            mandatory
            multiple
            density="comfortable"
            divided
            class="v-btn-toggle-custom"
            shaped
          >
            <v-btn
              v-for="day in Object.values(EnumDays).filter(
                v => !isNaN(Number(v)),
              )"
              :key="day"
              :value="day"
              :text="$t('dashboard.time-slot.short-day-' + day)"
              :size="xs ? 'x-small' : 'small'"
            />
          </v-btn-toggle>
        </div>
      </v-card-text>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>

        <v-btn
          v-if="dialogStore.isEditing"
          class="px-6"
          variant="outlined"
          color="red-darken-1"
          :loading="loadingDeletion"
          @click="handleAskDeleteTimeSlot"
        >
          {{ $t('dashboard.time-slot.delete') }}
        </v-btn>

        <v-btn
          class="px-6"
          variant="flat"
          color="blue-darken-1"
          :loading="timeSlotStore.loading"
          :disabled="!dialogStore.formValid"
          @click="verify"
        >
          {{ $t('dashboard.time-slot.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Confirm dialog -->
  <v-dialog
    v-model="confirmDialog"
    persistent
    :min-width="xs ? 0 : 500"
    width="fit-content"
    transition="dialog-top-transition"
  >
    <v-card class="bg-white">
      <v-card-title
        class="d-flex pa-4 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        {{ titleConfirmDialog }}
      </v-card-title>
      <v-card-text class="mx-auto">
        <div class="mb-4">{{ messageConfirmDialog }}</div>
        {{ applyForMessage }}
        <v-radio-group color="blue" v-model="applyChangesFor">
          <v-radio
            :label="
              $t('dashboard.time-slot.dialogTimeSlot.allFutureOccurrences')
            "
            :value="
              confirmDialogMode === 'deletion' ? 'removeAfterDate' : 'all'
            "
          ></v-radio>
          <div
            v-if="
              applyChangesFor === 'removeAfterDate' || applyChangesFor === 'all'
            "
            class="font-italic"
          >
            {{ messageChangeAll }}
          </div>
          <v-radio
            :label="
              $t('dashboard.time-slot.dialogTimeSlot.onlyThisOccurrences')
            "
            value="single"
          ></v-radio>
          <div v-if="applyChangesFor === 'single'" class="font-italic">
            {{ messageChangeSingle }}
          </div>
        </v-radio-group>
      </v-card-text>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>

        <v-btn
          class="px-6"
          variant="outlined"
          color="black"
          @click="resetConfirmDialog"
        >
          {{ $t('confirmDialog.cancelBtn') }}
        </v-btn>

        <v-btn
          class="px-6"
          variant="flat"
          color="blue-darken-1"
          :loading="timeSlotStore.loading"
          @click="applyChanges"
        >
          {{ confirmBtnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import '@holitime/schedule-x-theme-default/dist/date-picker.css'
import { Ref, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import createHttpClient from '@/api/httpClient'
import { ApiResponse } from '@/api/api'
import {
  VerifyTimeSlotParams,
  VerifyTimeSlotResponse,
} from '@/api/timeslot/verify'

import { useAppointmentReasonStore } from '@/store/appointment-reason/appointementReasonStore'
import { useTimeSlotStore } from '@/store/timeslot/timeSlotStore'
import { useDialogTimeSlotStore } from '@/store/timeslot/dialogTimeSlotStore'
import { EnumDays } from '@/shared/enumDays'

import ConfirmDialogService from '../feedback/confirmDialog/confirmDialogService'
import MessageService from '../feedback/message/messageService'
import SelectProfileAndAddress from './SelectProfileAndAddress.vue'
import SelectDateAndTime from './SelectDateAndTime.vue'
import DialogMotive from './DialogMotive.vue'
import LoadingBackdropService from '../feedback/loadingBackdrop/loadingBackdropService'
import EventBus from '@/utils/eventBus'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useDisplay } from 'vuetify/lib/framework.mjs'

dayjs.extend(utc)
dayjs.extend(timezone)

const { xs } = useDisplay()
const httpClient = createHttpClient()
const { t } = useI18n()
const dialogStore = useDialogTimeSlotStore()
const timeSlotStore = useTimeSlotStore()

const appointmentReasonStore = useAppointmentReasonStore()
const clearMotives = () => {
  dialogStore.selectedAppointmentReasons = null
}

const showCreateMotiveDialog = ref(false)
const toggleCreateMotiveDialog = () => {
  showCreateMotiveDialog.value = true
}
const numberRule = v => {
  if (v && !String(v).trim()) return true
  if (!isNaN(parseFloat(v)) && v > 0) return true
  return t('dashboard.time-slot.errorNumberRule')
}

const verify = () => {
  if (confirmDialogMode.value === 'edition') {
    applyChangesFor.value = 'all'
  }
  const verifyPayload: VerifyTimeSlotParams = {
    profile: dialogStore.selectedProfile.id,
    address: dialogStore.selectedAddress.id,
    days: dialogStore.selectedDays,
    weekRepeat: dialogStore.repeatTimeSlot === 'yes',
    weekRepetition:
      dialogStore.repeatTimeSlot === 'yes'
        ? Number(dialogStore.everyXWeek)
        : null,
    date: dialogStore.selectedDate,
    startTime: dialogStore.selectedStartTime,
    endTime: dialogStore.selectedEndTime,
    remote: dialogStore.isRemote,
    presential: dialogStore.isPresential,
    appointmentReasons: dialogStore.selectedAppointmentReasons.map(ar => ({
      appointmentReason: ar.id,
    })),
    timezone: dayjs.tz.guess(),
  }
  timeSlotStore.handleCreateOrUpdateTimeSlot(
    verifyPayload,
    applyChangesFor.value,
  )
}

/** Confirm dialog for edition of multiple occurrences */
const confirmDialogMode: Ref<'deletion' | 'edition'> = ref('edition')
const confirmDialog = ref(false)
EventBus.on('closeTimeslotConfirmDialog', () => {
  confirmDialog.value = false
})
const applyChangesFor: Ref<'removeAfterDate' | 'single' | 'all'> =
  ref('removeAfterDate')
const applyForMessage = ref(
  t('dashboard.time-slot.dialogTimeSlot.applyChangesFor'),
)
const titleConfirmDialog = ref(
  t('dashboard.time-slot.dialogTimeSlot.confirmEdition'),
)
const messageConfirmDialog = ref('')
const messageChangeAll = ref('')
const messageChangeSingle = ref('')
const confirmBtnText = ref(t('confirmDialog.confirmBtn'))

const resetConfirmDialog = () => {
  messageConfirmDialog.value = ''
  messageChangeAll.value = ''
  messageChangeSingle.value = ''
  applyForMessage.value = t(
    'dashboard.time-slot.dialogTimeSlot.applyChangesFor',
  )
  confirmDialogMode.value = 'edition'
  confirmDialog.value = false
  applyChangesFor.value = 'all'
  titleConfirmDialog.value = t(
    'dashboard.time-slot.dialogTimeSlot.confirmEdition',
  )
  messageChangeAll.value = ''
  messageChangeSingle.value = ''
  confirmBtnText.value = t('confirmDialog.confirmBtn')
}

const updateMsgChangeAll = msg => {
  messageChangeAll.value = msg
}

const updateMsgChangeSingle = msg => {
  messageChangeSingle.value = msg
}

EventBus.on(
  'openModalConfirm',
  ({ mode, message, messageChangeAll, messageChangeSingle, change }) => {
    resetConfirmDialog()
    confirmDialogMode.value = mode
    messageConfirmDialog.value = message
    updateMsgChangeAll(messageChangeAll)
    updateMsgChangeSingle(messageChangeSingle)
    confirmDialog.value = true
    applyChangesFor.value = change
  },
)

const loadingDeletion = ref(false)
const handleAskDeleteTimeSlot = async () => {
  const NO_VERIFY = true
  try {
    LoadingBackdropService.start()
    let response: ApiResponse<VerifyTimeSlotResponse>
    let responseSingle: ApiResponse<VerifyTimeSlotResponse>
    if (!NO_VERIFY) {
      response = await httpClient.delete(
        `/timeslot/${dialogStore.timeSlotId}/verify?date=${dialogStore.selectedDate}&change=removeAfterDate`,
      )
      responseSingle = await httpClient.delete(
        `/timeslot/${dialogStore.timeSlotId}/verify?date=${dialogStore.selectedDate}&change=single`,
      )
    }
    if (!NO_VERIFY && response.status != 200)
      throw Error(JSON.stringify(response))

    if (dialogStore.repeatTimeSlot == 'yes') {
      if (!NO_VERIFY && response) {
        messageConfirmDialog.value = response.data.message
        messageChangeAll.value = response.data.messageChangeAll
        messageChangeSingle.value = responseSingle.data.messageChangeSingle
      } else {
        messageConfirmDialog.value = t(
          'dashboard.time-slot.dialogTimeSlot.validateDeleteSlot',
        )
        applyChangesFor.value = 'removeAfterDate'
      }
      confirmDialog.value = true
      confirmDialogMode.value = 'deletion'
      titleConfirmDialog.value = t(
        'dashboard.time-slot.dialogTimeSlot.confirmDeletion',
      )
      applyForMessage.value = t(
        'dashboard.time-slot.dialogTimeSlot.applyDeletionFor',
      )
    } else {
      ConfirmDialogService.confirm({
        title: t('dashboard.time-slot.dialogTimeSlot.confirmDeletion'),
        message: '',
        optionsConfirm: {
          onConfirm: () => {
            handleConfirmDeletion()
          },
          onCancel: () => {},
        },
      })
    }
  } catch (err) {
    MessageService.error(t('dashboard.time-slot.dialogTimeSlot.errorDelete'))
  }
  LoadingBackdropService.stop()
}

const applyChanges = () => {
  if (confirmDialogMode.value === 'deletion') {
    handleConfirmDeletion()
  }
  if (confirmDialogMode.value === 'edition') {
    const verifyPayload: VerifyTimeSlotParams = {
      profile: dialogStore.selectedProfile.id,
      address: dialogStore.selectedAddress.id,
      days: dialogStore.selectedDays,
      weekRepeat: dialogStore.repeatTimeSlot === 'yes',
      weekRepetition:
        dialogStore.repeatTimeSlot === 'yes'
          ? Number(dialogStore.everyXWeek)
          : null,
      date: dialogStore.selectedDate,
      startTime: dialogStore.selectedStartTime,
      endTime: dialogStore.selectedEndTime,
      remote: dialogStore.isRemote,
      presential: dialogStore.isPresential,
      appointmentReasons: dialogStore.selectedAppointmentReasons.map(ar => ({
        appointmentReason: ar.id,
      })),
      timezone: dayjs.tz.guess(),
    }
    timeSlotStore.handleConfirmTimeSlot(verifyPayload, applyChangesFor.value)
  }
}

// Date format YYYY-MM-DD of the  UTC timezone
const handleConfirmDeletion = async () => {
  try {
    LoadingBackdropService.start()

    let date = dayjs(
      `${dialogStore.selectedDate} ${dialogStore.selectedStartTime}:${dialogStore.selectedEndTime}`,
      'YYYY-MM-DD HH:mm',
    )
      .utc()
      .format('YYYY-MM-DD')
    let deleteUrl = `/timeslot/${dialogStore.timeSlotId}/validate?applyChangesFor=${applyChangesFor.value}`
    deleteUrl += `&date=${date}`

    let response: ApiResponse<VerifyTimeSlotResponse> =
      await httpClient.delete(deleteUrl)

    if (response.status != 200) throw Error(JSON.stringify(response))
    MessageService.success(
      t('dashboard.time-slot.dialogTimeSlot.successDelete'),
    )
    await timeSlotStore.initTimeSlots()
    dialogStore.toggleDialogTimeSlot()
  } catch (err) {
    MessageService.error(t('dashboard.time-slot.dialogTimeSlot.errorDelete'))
  }
  LoadingBackdropService.stop()
  confirmDialog.value = false
  resetConfirmDialog()
}

function ajouterUneHeure(heureStr: string): string {
  // Séparer l'heure et les minutes
  const [heures, minutes] = heureStr.split(':').map(Number)

  // Créer un objet Date avec l'heure actuelle
  const date = new Date()
  date.setHours(heures)
  date.setMinutes(minutes)

  // Ajouter une heure
  date.setHours(date.getHours() + 1)

  // Récupérer les nouvelles heures et minutes
  const nouvellesHeures = String(date.getHours()).padStart(2, '0')
  const nouvellesMinutes = String(date.getMinutes()).padStart(2, '0')

  // Retourner l'heure au format HH:mm
  return `${nouvellesHeures}:${nouvellesMinutes}`
}

function onUpdateStartTime() {
  dialogStore.selectedEndTime = ajouterUneHeure(dialogStore.selectedStartTime)
}
const validateIsRemote = newReason => {
  if (dialogStore.selectedAddress.remote) {
    const lastReason = newReason[newReason.length - 1]
    if (lastReason.remote === 'no') {
      MessageService.error(
        t('dashboard.time-slot.dialogTimeSlot.reasonRemoteValidation'),
      )
    }
  }
}
</script>

<style scoped>
.close-icon {
  position: absolute;
  right: 0;
}
.input-every-x-week {
  width: 66px;
  &:deep() input.v-field__input {
    text-align: center;
  }
}
.v-btn-toggle-custom {
  width: fit-content;
  border: 1px solid grey;
  :deep() {
    background-color: #fff;
  }
}
.select-motive {
  max-width: 80%;
}
.timeSlotDialogModal {
  max-width: 600px;
}
.text-ellipsis {
  text-overflow: ellipsis;
}
</style>
<style>
@media (max-width: 768px) {
  .radio-group-custom .v-selection-control-group label {
    font-size: 12px;
  }
  .v-field__clearable {
    opacity: 1 !important;
  }
  .timeSlotDialogModal {
    width: 100%;
    max-width: 500px;
  }
  .v-btn-group--density-comfortable.v-btn-group {
    height: 35px !important;
  }
  .v-btn-toggle-custom {
    overflow: hidden;
  }
  .select-reason {
    & .v-field__input {
      padding: 8px !important;
      font-size: 12px !important;
      & input {
        top: 12px;
        left: 12px;
      }
    }
  }
  .v-dialog > .v-overlay__content {
    max-width: calc(100% - 18px);
    margin: 9px;
  }
  .text-custom-in-mobile {
    font-size: 12px;
  }
}
</style>
