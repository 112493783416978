import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import createHttpClient from '@/api/httpClient'
import { AxiosResponse } from 'axios'
import {
  InitPractitionerAppointmentParams,
  MessageResponse,
  PractitionerAppointmentEvent,
  PractitionerAppointmentInformationData,
  PractitionerAppointmentPostParams,
  PatientData,
  PatientAugmentedData,
  PractitionerAppointmentPutParams,
  PatientDataForCalendar,
} from './practitionerAppointmentEvent'
import { calendarApp } from '@/components/calendar/calendarApp'
import { calendarsUpdaterPlugin } from '@/components/calendar/calendarState'
import {
  colorAppointmentReasons,
  profileColors,
} from '@/components/calendar/calendarApp'
import { useUserStore } from '../user/userStore'
import MessageService from '@/components/feedback/message/messageService'
import { useI18n } from 'vue-i18n'
import { ProfileInformationData } from '@/api/profile/profile.d'
import { AddressInformationData } from '@/api/account/address'
import { useVacationEventStore } from '../vacation/vacationEventStore'
import { ApiResponse } from '@/api/api'
import { useAppointmentReasonStore } from '../appointment-reason/appointementReasonStore'
import LoadingBackdropService from '@/components/feedback/loadingBackdrop/loadingBackdropService'
import { useDialogPractitionerAppointmentStore } from './dialogAppointmentStore'
import utc from 'dayjs/plugin/utc'
import { AgendaResponse } from '@/api/timeslot/timeslot'

const httpClient = createHttpClient()
dayjs.extend(utc)

/**
 * This store is used in the appointment page of the dashboard of the account of the practitian
 */
export const usePractitionerAppointmentStore = defineStore(
  'practitioner-appointment',
  () => {
    const { t } = useI18n()
    const appointments: Ref<PractitionerAppointmentEvent[]> = ref([])
    const patients: Ref<PatientAugmentedData[]> = ref([])
    const filter = ref({
      profileIds: null,
      addressIds: null,
      appointmentReasonIds: null,
      patientId: null,
    })
    const errors = ref({})
    const fill = async (
      profileIds: string[],
      addressIds: string[],
      appointmentReasonIds: string[],
      patientId: string = null,
    ) => {
      try {
        const start = dayjs(
          calendarsUpdaterPlugin.$app.calendarState.range.value.start,
          'YYYY-MM-DD HH:mm',
        ).toISOString()

        const end = dayjs(
          calendarsUpdaterPlugin.$app.calendarState.range.value.end,
          'YYYY-MM-DD HH:mm',
        ).toISOString()

        const appointmentsResponse: AxiosResponse<AgendaResponse> =
          await httpClient.get(
            `/agenda/agenda?profiles=${profileIds.join(',')}&addresses=${addressIds.join(',')}` +
              `&appointmentReason=${appointmentReasonIds.join(',')}` +
              `&start=${start}&end=${end}` +
              `&owner=${patientId ? patientId : ''}`,
          )
        if (appointmentsResponse.status !== 200) {
          throw Error(JSON.stringify(appointmentsResponse))
        }
        appointments.value = formatResponse(
          appointmentsResponse.data.appointments.map(
            appointment => appointment.appointment,
          ),
        )
      } catch (error) {
        console.error(error)
      }
    }

    const formatResponse = (
      appointments: PractitionerAppointmentInformationData[],
    ): PractitionerAppointmentEvent[] => {
      const appointementReasonStore = useAppointmentReasonStore()

      if (!appointments || !appointments.length) return []
      return appointments.map((appointment): PractitionerAppointmentEvent => {
        const address = userStore.addresses.find(
          a => a.id == appointment.agenda.address.id,
        )
        const motive = appointementReasonStore.appointmentReasons.find(
          m => m.id == appointment.appointmentReason,
        )
        const fullName = appointment.owner
          ? appointment.owner.firstName + ' ' + appointment.owner.lastName
          : appointment.label

        return {
          id: appointment.id,
          type: 'PractitionerAppointmentEvent',
          start: dayjs(appointment.startDateTime).format('YYYY-MM-DD HH:mm'),
          end: dayjs(appointment.endDateTime).format('YYYY-MM-DD HH:mm'),
          title: fullName,
          address,
          patient: { ...appointment.owner, fullName } as PatientDataForCalendar,
          appointmentReason: motive,
          acceptRemote: appointment.presential ? 'in-person' : 'remote',
          description: appointment.comment ?? appointment.description,
          event_type: appointment.type,
          meetingLink: appointment.meetingLink,
          profileColor:
            profileColors[
              userStore.profiles.findIndex(
                p => p.id == appointment.agenda.profile.id,
              )
            ],

          appointmentReasonColor:
            colorAppointmentReasons[
              appointementReasonStore.appointmentReasons.findIndex(
                a => a.id == appointment.agenda.address.id,
              )
            ],
        }
      })
    }

    const getAddressIds: (
      addressItems: AddressInformationData[],
    ) => string[] = (addressItems: AddressInformationData[]) =>
      addressItems.map(a => a.id)

    const getProfileIds: (
      profileItems: ProfileInformationData[],
    ) => string[] = (profileItems: ProfileInformationData[]) =>
      profileItems.map(a => a.id)

    const userStore = useUserStore()
    const vacationStore = useVacationEventStore()
    const initAppointments = async (
      {
        profileIds,
        addressIds,
        appointmentReasonIds,
        patientId,
      }: InitPractitionerAppointmentParams = {
        profileIds: null,
        addressIds: null,
        appointmentReasonIds: null,
        patientId: null,
      },
    ) => {
      try {
        LoadingBackdropService.start()
        calendarApp.value.events.set([])
        await vacationStore.initVacations()

        await userStore.initAddresses()
        if (!addressIds || !addressIds.length) {
          addressIds = getAddressIds(userStore.addresses)
        }

        await userStore.initProfiles()
        if (!profileIds || !profileIds.length) {
          profileIds = getProfileIds(userStore.profiles)
        }

        const appointReasonStore = useAppointmentReasonStore()
        await appointReasonStore.fillAppointmentReasons()
        if (!appointmentReasonIds || !appointmentReasonIds.length) {
          appointmentReasonIds = appointReasonStore.appointmentReasons.map(
            ar => ar.id,
          )
        }

        await fill(profileIds, addressIds, appointmentReasonIds, patientId)

        const events = [...appointments.value, ...vacationStore.vacations]
        calendarApp.value.events.set(events)
      } catch (error) {
        console.error(error)
        MessageService.error(t('common.error.errorHasOccurred'))
      }
      LoadingBackdropService.stop()
    }

    const loading = ref(false)

    const handleCreateAppointment = async (
      appointmentPayload: PractitionerAppointmentPostParams,
    ) => {
      const dialog = useDialogPractitionerAppointmentStore()
      try {
        loading.value = true
        const response: ApiResponse<MessageResponse> = await httpClient.post(
          '/appointment/',
          appointmentPayload,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        if (response.status == 422) {
          if ('invalid date' === response.data.message) {
            MessageService.error(t('common.error.invalidDate'))
          } else MessageService.error(response.data.message)
        } else if (response.status != 200) {
          throw Error(JSON.stringify(response))
        }
        await initAppointments(filter.value)
        MessageService.success(response.data.message)
        dialog.resetData()
        dialog.isOpen = false
      } catch (err) {
        MessageService.error(err.response?.data?.message)
        errors.value = err.response.data
        dialog.isOpen = true
      }

      loading.value = false
    }
    const errorMessage = key => {
      return errors.value[key] ? errors.value[key][0] : ''
    }

    const handleUpdateAppointment = async (
      id: string,
      appointmentPayload: PractitionerAppointmentPutParams,
    ) => {
      try {
        loading.value = true
        const response: ApiResponse<MessageResponse> = await httpClient.put(
          '/appointment/' + id,
          appointmentPayload,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        if (response.status == 422) {
          MessageService.error(response.data.message)
        } else if (response.status != 200) {
          throw Error(JSON.stringify(response))
        }
        await initAppointments(filter.value)
        MessageService.success(response.data.message)
      } catch (err) {
        MessageService.error(err.response?.data?.message)
      }

      loading.value = false
    }

    const handleDeleteAppointment = async (appointmentId: string) => {
      try {
        loading.value = true
        const response: ApiResponse<MessageResponse> = await httpClient.delete(
          '/appointment/' + appointmentId,
        )

        if (response.status == 422) {
          MessageService.error(response.data.message)
        } else if (response.status != 200) {
          throw Error(JSON.stringify(response))
        }
        await initAppointments(filter.value)
        MessageService.success(response.data.message)
      } catch (err) {
        MessageService.error(err.response?.data?.message)
      }
      const dialog = useDialogPractitionerAppointmentStore()
      dialog.resetData()
      dialog.isOpen = false
      loading.value = false
    }

    const formatPatients = (users: PatientData[]): PatientAugmentedData[] =>
      users.map(u => ({ ...u, fullName: u.firstName + ' ' + u.lastName }))
    /** name="firstname lastname" */
    const fillPatients = async (name: string) => {
      try {
        loading.value = true
        const response: ApiResponse<PatientData[]> = await httpClient.get(
          '/appointment/users?name=' + name,
        )
        if (response.status != 200) {
          throw Error(JSON.stringify(response))
        }
        patients.value = formatPatients(response.data)
      } catch (err) {
        // MessageService.error(t('common.error.errorHasOccurred'))
      }

      loading.value = false
    }

    return {
      appointments,
      patients,
      filter,
      loading,
      initAppointments,
      formatResponse,
      handleCreateAppointment,
      handleUpdateAppointment,
      handleDeleteAppointment,
      formatPatients,
      fillPatients,
      fill,
      errorMessage,
    }
  },
)
