import { defineStore } from 'pinia'
import createHttpClient from '@/api/httpClient'
import { ref } from 'vue'
import { Status } from '@/components/profil-step/profil-step.d'

const req = createHttpClient()

export const useDashboardStatus = defineStore('dashboard-status', () => {
  const receivedStatus = ref<Status>({
    myAccount: false,
    myAddresses: false,
    myProfils: false,
    mySubscription: false,
    myTimeslots: false,
  })

  const loadStatus = ref(false)

  const fetchDashboardStatus = async () => {
    if (loadStatus.value) return false
    loadStatus.value = true
    try {
      const { data } = await req.get('/account/dashboard-status')
      receivedStatus.value = { ...data }
    } catch {
    } finally {
      loadStatus.value = false
    }
  }

  return {
    fetchDashboardStatus,
    receivedStatus,
    loadStatus,
  }
})
