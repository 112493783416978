<template>
  <form-block
    v-if="profiles.length"
    :title="$t('dashboard.profile.profile', 2)"
  >
    <div
      v-for="(profil, idx) in profiles"
      :key="idx"
      class="mb-5 px-4 py-2 rounded-lg overflow-x-auto overflow-y-hidden"
      :class="{ 'profile-selected': profil.id === currentProfileId }"
    >
      <div class="d-flex justify-space-between align-center">
        <h3 class="text-primary text-capitalize">
          {{ $t('dashboard.profile.profile') }} {{ idx + 1 }}
        </h3>
        <v-btn
          :to="{
            name: 'PractitionerPublicProfile',
            params: {
              discipline: profil.mainDiscipline.slug,
              city: profil.profileAddresses[0]?.address?.city,
              practitionerSlug: profil.slug,
            },
          }"
          append-icon="mdi-open-in-new"
          density="comfortable"
          target="_blank"
          slim
          >{{ $t('dashboard.profile.see') }}</v-btn
        >
      </div>
      <v-row align="center" justify="space-between">
        <v-col cols="auto">
          <div class="d-flex align-center">
            <v-avatar size="80">
              <v-icon v-show="!profil.avatar" size="80" color="secondary"
                >mdi-account-circle</v-icon
              >
              <v-img
                v-show="profil.avatar"
                :src="profil.avatar?.url"
                :cover="true"
              ></v-img>
            </v-avatar>
            <div class="ml-2">
              <h3 class="text-primary">{{ profil.slug }}</h3>
              <div class="d-flex ga-2">
                <v-chip
                  v-for="(discipline, idx) in profil.disciplines"
                  :key="idx"
                  color="surface"
                  variant="flat"
                  rounded="lg"
                  size="small"
                  >{{ discipline.name }}</v-chip
                >
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="auto" class="d-flex align-center">
          <v-btn
            @click="profileSelected(profil)"
            variant="outlined"
            color="secondary"
            class="px-10 border-md border-secondary mr-2"
            :active="profil.id === currentProfileId"
            >{{ $t('dashboard.profile.editBtn') }}</v-btn
          >
          <v-btn
            density="comfortable"
            color="red"
            size="small"
            icon="mdi-close"
            @click="alertProfile = true"
          ></v-btn>
          <AlertDeleteProfilDialog
            :display="alertProfile"
            @cancel="alertProfile = false"
            @confirmDelete="(alertProfile = false), deleteProfil(profil.id)"
          />
        </v-col>
      </v-row>
    </div>
    <!--  -->
    <div
      class="d-flex align-center ga-2 mt-8"
      v-if="profiles.length && profiles.length < 3"
    >
      <v-btn @click="new_profile" prepend-icon="mdi-plus" color="secondary">{{
        $t('dashboard.profile.addBtn')
      }}</v-btn>
    </div>
    <div class="mt-3 text-caption">
      <p>{{ $t('dashboard.profile.hint') }}</p>
    </div>
  </form-block>

  <form-block
    id="newProfil"
    ref="formBlock1"
    :title="$t('profileForm.baseInformations.title')"
    :is-new="currentProfileId === null"
    @update:is-valid="isBaseForm1Valid = $event"
  >
    <v-row>
      <v-col cols="12" class="mt-2 pb-0">
        <div class="text-caption text-secondary">
          {{ $t('profileForm.avatar.label') }}
        </div>
      </v-col>
      <v-col
        cols="12"
        class="d-flex flex-column flex-sm-row gap-h align-center pt-0"
      >
        <v-avatar size="80">
          <v-icon v-show="!form.avatar.src" size="80" color="secondary"
            >mdi-account-circle</v-icon
          >
          <v-img
            v-show="form.avatar.src"
            :src="form.avatar.src"
            :cover="true"
          ></v-img>
        </v-avatar>
        <div class="d-flex flex-column gap-v mt-4 ml-md-3">
          <div class="d-flex justify-center gap-v">
            <v-btn
              size="small"
              color="secondary"
              rounded="false"
              @click="handleUploadAvatar"
            >
              {{ $t('accountForm.avatar.updateBtn') }}
            </v-btn>
          </div>
          <span class="text-subtitle-2 font-weight-light">{{
            $t('accountForm.avatar.caption')
          }}</span>
          <span
            v-if="form.avatar.error"
            class="text-caption text-error animate-slide-down"
            >{{ form.avatar.error }}</span
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <autocomplete-chips
          :key="disciplineEditorKey"
          v-model="form.disciplines.value"
          :label="$t('profileForm.disciplines.label')"
          :items="disciplines"
          item-title="name"
          item-value="id"
          :rules="disciplinesRules"
          :error-messages="form.disciplines.error"
          counter="3"
          :persistent-counter="
            form.disciplines.value.length >= MAX_SPECIALITIES
          "
          color="secondary"
          :no-data-text="$t('profileForm.disciplines.noDataText')"
        >
        </autocomplete-chips>
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-col>
        <i18n-t keypath="profileForm.disciplines.suggest">
          <template #link>
            <a href="#" @click.prevent="suggestDisciplineDialog = true">
              {{ $t('profileForm.disciplines.suggestLinkLabel') }}
            </a>
          </template>
        </i18n-t>
      </v-col>
    </v-row>
    <suggest-discipline-dialog
      :dialog="suggestDisciplineDialog"
      @update:dialog="suggestDisciplineDialog = $event"
    ></suggest-discipline-dialog>
    <v-row v-if="form.disciplines.value.length > 1">
      <v-col>
        <v-select
          v-model="form.mainDiscipline.value"
          :label="$t('profileForm.mainDiscipline.label')"
          :hint="$t('profileForm.mainDiscipline.hint')"
          persistent-hint
          :items="mainDisciplinesItems"
          :error-messages="form.mainDiscipline.error"
          item-title="name"
          item-value="id"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <autocomplete-chips
          v-model="form.practitionerServices.value"
          :label="$t('profileForm.practitionerServices.label')"
          :hint="$t('profileForm.practitionerServices.hint')"
          persistent-hint
          :items="practitionerServices"
          item-title="name"
          item-value="id"
          :rules="practitionerServicesRules"
          :error-messages="form.practitionerServices.error"
          color="secondary"
          :return-object="false"
          :no-data-text="$t('profileForm.practitionerServices.noDataText')"
        >
        </autocomplete-chips>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <autocomplete-chips
          :key="audiencesEditorKey"
          v-model="form.targetAudiences.value"
          :label="$t('profileForm.targetAudiences.label')"
          :items="targetAudiences"
          item-title="name"
          item-value="id"
          :rules="targetAudiencesRules"
          :error-messages="form.targetAudiences.error"
          color="secondary"
        >
        </autocomplete-chips>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <autocomplete-chips
          :key="paymentEditorKey"
          v-model="form.paymentMethods.value"
          :label="$t('profileForm.paymentMethods.label')"
          :items="paymentMethods"
          item-title="name"
          item-value="id"
          :rules="paymentMethodsRules"
          :error-messages="form.paymentMethods.error"
          color="secondary"
        >
        </autocomplete-chips>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <autocomplete-chips
          :key="spokenLanguagesEditorKey"
          v-model="form.spokenLanguages.value"
          :label="$t('profileForm.spokenLanguages.label')"
          :items="languages"
          item-title="nativeName"
          item-value="code"
          :rules="spokenLanguagesRules"
          :error-messages="form.spokenLanguages.error"
          color="secondary"
        >
        </autocomplete-chips>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="form.email.value"
          :label="$t('profileForm.email.label')"
          :hint="$t('profileForm.email.hint')"
          :rules="emailRules"
          :error-messages="form.email.error"
          color="secondary"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <phone
          v-model="form.phone.value"
          :label="$t('profileForm.phone.label')"
          :hint="$t('profileForm.phone.hint')"
          :error-messages="form.phone.error"
          :show-dial-code="false"
          rounded
          required
          @change="onPhoneChange"
          @valide="form.phone.valid = $event"
        ></phone>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          v-model="form.webSite.value"
          :label="$t('profileForm.website')"
          :rules="websiteRules"
          :error-messages="form.webSite.error"
          color="secondary"
          placeholder="https://"
        ></v-text-field>
      </v-col>
    </v-row>
  </form-block>

  <form-block
    ref="formBlock2"
    :title="$t('profileForm.presentation.title')"
    @update:is-valid="isForm2Valid = $event"
  >
    <v-row>
      <v-col>
        <v-textarea
          v-model="form.tagline.value"
          :label="$t('profileForm.tagline.label')"
          :hint="$t('profileForm.tagline.hint')"
          :rules="taglineRules"
          :error-messages="form.tagline.error"
          color="secondary"
          rows="2"
          :counter="SHORT_PRESENTATION_MAX_LENGTH"
          persistent-counter
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>
          <rich-editor
            ref="mainDescriptionEditor"
            :key="mainDescriptionEditorKey"
            v-model:content="form.mainDescription.value"
            contentType="html"
            :label="$t('profileForm.mainDescription.label')"
            :hint="$t('profileForm.mainDescription.hint')"
            :rules="mainDescriptionRules"
            :error-messages="form.mainDescription.error"
            color="secondary"
            :counter="LONG_PRESENTATION_MAX_LENGTH"
            @is-valid="form.mainDescription.valid = $event"
          >
          </rich-editor>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>
          <rich-editor
            v-model:content="form.sessionDescription.value"
            :key="sessionDescriptionEditorKey"
            contentType="html"
            :label="$t('profileForm.sessionDescription.label')"
            :hint="$t('profileForm.sessionDescription.hint')"
            :rules="sessionDescriptionRules"
            :error-messages="form.sessionDescription.error"
            color="secondary"
            :counter="SESSION_DESCRIPTION_MAX_LENGTH"
            @is-valid="form.sessionDescription.valid = $event"
          >
          </rich-editor>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>
          <rich-editor
            v-model:content="form.expectedBenefits.value"
            :key="expectedBenefitsEditorKey"
            contentType="html"
            :label="$t('profileForm.expectedBenefits.label')"
            :hint="$t('profileForm.expectedBenefits.hint')"
            :rules="expectedBenefitsRules"
            :error-messages="form.expectedBenefits.error"
            color="secondary"
            :counter="EXPECTED_BENEFITS_MAX_LENGTH"
            @is-valid="form.expectedBenefits.valid = $event"
          >
          </rich-editor>
        </div>
      </v-col>
    </v-row>
  </form-block>

  <form-block :title="$t('profileForm.photos.title')">
    <div class="text-center mb-2">{{ $t('profileForm.photos.subtitle') }}</div>
    <v-row>
      <v-col
        v-for="photo in form.photos.value"
        :key="photo.src"
        cols="6"
        sm="4"
        md="3"
      >
        <v-img
          :src="photo.src"
          :cover="true"
          class="mb-2"
          width="100%"
          aspect-ratio="1"
          rounded="lg"
        >
          <v-btn
            color="error"
            icon="mdi-close"
            class="position-absolute"
            style="top: 5px; right: 5px; z-index: 1"
            density="compact"
            @click="
              form.photos.value.splice(form.photos.value.indexOf(photo), 1)
            "
          >
          </v-btn>
        </v-img>
        <error-list :errors="photo.error" />
      </v-col>
      <v-col
        v-if="form.photos.value.length < MAX_PHOTOS"
        cols="6"
        sm="4"
        md="3"
      >
        <div class="w-100" style="aspect-ratio: 1">
          <v-btn
            size="100%"
            rounded="lg"
            color="grey-lighten-3"
            @click="handleUploadPhotos"
            stacked
          >
            <v-icon size="25px" icon="mdi-plus" color="primary"></v-icon>
            <div class="text-primary">
              {{ $t('profileForm.photos.addBtn') }}
            </div>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <error-list :errors="form.photos.error" />
  </form-block>

  <form-block
    ref="formBlock3"
    :title="$t('profileForm.degrees.title')"
    @update:is-valid="isForm3Valid = $event"
  >
    <div class="text-center mb-2">{{ $t('profileForm.degrees.subtitle') }}</div>
    <v-row>
      <v-col cols="12">
        <v-sheet
          v-for="(degreeForm, index) in form.degrees.value"
          :key="index"
          border
          rounded="lg"
          class="pa-2 mt-3"
          color="transparent"
        >
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="degreeForm.schoolName.value"
                :label="$t('profileForm.degrees.schoolName.label')"
                :rules="requiredRules"
                color="secondary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="degreeForm.discipline.value"
                :label="$t('profileForm.degrees.discipline.label')"
                :rules="requiredRules"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="degreeForm.graduationYear.value"
                :items="years"
                :label="$t('profileForm.degrees.graduationYear.label')"
                :rules="requiredRules"
                color="secondary"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-sheet
                color="white"
                width="100%"
                rounded="xl"
                class="px-2 d-flex align-center justify-center border-opacity-50"
                border
              >
                <v-select
                  v-model="degreeForm.durationValue.value"
                  :label="$t('profileForm.degrees.durationValue.label')"
                  variant="solo"
                  bg-color="transparent"
                  color="secondary"
                  flat
                  hide-details
                  :items="Array.from({ length: 10 }, (v, i) => i + 1)"
                  style="min-width: 100px"
                ></v-select>
                <!--                <v-divider thickness="2" vertical inset class="mx-2"></v-divider>-->
                <v-select
                  v-model="degreeForm.durationUnit.value"
                  class="flex-0-0"
                  :items="durationUnits"
                  item-title="name"
                  item-value="id"
                  bg-color="transparent"
                  variant="solo"
                  single-line
                  flat
                  hide-details
                  density="compact"
                ></v-select>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="degreeForm.title.value"
                :label="$t('profileForm.degrees.description.label')"
                :rules="requiredRules"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div ref="uppyDocuments"></div>
              <v-chip
                v-for="(document, index) in degreeForm.documents.value"
                :key="document.src"
                class="ma-1 cursor-pointer"
                color="secondary"
                closable
                @click="() => seeDocument(document.src)"
                @click:close="degreeForm.documents.value.splice(index, 1)"
              >
                {{ document.name }}
              </v-chip>
              <v-btn
                v-if="degreeForm.documents.value.length < MAX_DOCUMENTS"
                color="secondary"
                prepend-icon="mdi-plus"
                size="small"
                @click="handleUploadDegreeDocuments(degreeForm.documents.value)"
              >
                {{ $t('profileForm.degrees.addDocumentBtn') }}
              </v-btn>
            </v-col>
            <v-col cols="12" class="text-caption">
              {{ $t('profileForm.degrees.documentHint') }}
            </v-col>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                color="error"
                variant="outlined"
                size="small"
                @click="form.degrees.value.splice(index, 1)"
              >
                {{ $t('profileForm.degrees.deleteBtn') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col cols="12">
        <v-btn
          v-if="form.degrees.value.length < 5"
          color="secondary"
          rounded="lg"
          @click="addDegree"
        >
          {{
            form.degrees.value.length < 1
              ? $t('profileForm.degrees.addBtn')
              : $t('profileForm.degrees.addAnotherBtn')
          }}
        </v-btn>
      </v-col>
    </v-row>
    <div class="pt-8">
      <error-list :errors="rootErrors" />
    </div>
  </form-block>

  <v-row v-if="!embedded" class="mt-4">
    <v-col class="d-flex justify-center align-center">
      <v-btn color="accent" @click="submit" :loading="submitLoading">
        {{
          currentProfileId ? $t('commonForm.updateBtn') : $t('save_new_profil')
        }}
        <!-- create or update new profile -->
      </v-btn>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { ref, onMounted, nextTick, computed, watch } from 'vue'
import FormBlock from '@/components/common/FormBlock.vue'
import AvatarService from '@/services/avatarService'

import PhotoService, {
  setMaxNumberOfFiles as setMaxNumberOfPhotos,
} from '@/services/photoService'
import DocumentService, {
  setMaxNumberOfFiles as setMaxNumberOfDocuments,
} from '@/services/documentService'
import { useI18n } from 'vue-i18n'
import createHttpClient from '@/api/httpClient'
import AutocompleteChips from '@/components/inputs/AutocompleteChips.vue'
import languages from '@/assets/languages.json'
import { EmailRegEx } from '@/utils/regex'
import Phone from '@/components/inputs/phone/Phone.vue'
import RichEditor from '@/components/inputs/RichEditor.vue'
import {
  handleServerErrors,
  resetErrors,
  scrollToFirstError,
} from '@/services/validation'
import MessageService from '@/components/feedback/message/messageService'
import ErrorList from '@/components/forms/ErrorList.vue'
import { useAppStore } from '@/store/app'
import SuggestDisciplineDialog from '@/components/dialog/SuggestDisciplineDialog.vue'
import AlertDeleteProfilDialog from '@/components/dialog/AlertDeleteProfilDialog.vue'
import { useDashboardStatus } from '@/store/dashboard-status/dashboardStatus'
import { useUserStore } from '@/store/user/userStore'

const userStore = useUserStore()
const MAX_SPECIALITIES = 3
const MAX_PRACTITIONER_SERVICES = 5
const SHORT_PRESENTATION_MAX_LENGTH = 100
const LONG_PRESENTATION_MAX_LENGTH = 1000
const LONG_PRESENTATION_MIN_LENGTH = 100
const SESSION_DESCRIPTION_MAX_LENGTH = 1000
const EXPECTED_BENEFITS_MAX_LENGTH = 1000
const MAX_PHOTOS = 5
const MAX_DOCUMENTS = 3

const $t = useI18n().t
const appStore = useAppStore()

defineProps({
  embedded: Boolean,
})
const emit = defineEmits(['submitLoading'])
const dashboardState = useDashboardStatus()

const degreeForm = ref({
  schoolName: { value: '', error: '' },
  discipline: { value: '', error: '' },
  title: { value: '', error: '' },
  graduationYear: { value: null, error: '' },
  durationValue: { value: null, error: '', hasError: false },
  durationUnit: { value: 'years', error: '' },
  documents: { value: [], error: '' },
  error: { value: '', error: '' },
})

const form = ref({
  avatar: {
    src: '',
    key: '',
    error: '',
  },
  disciplines: {
    value: [],
    error: '',
  },
  mainDiscipline: {
    value: null,
    error: '',
  },
  practitionerServices: {
    value: [],
    error: '',
  },
  targetAudiences: {
    value: [],
    error: '',
  },
  paymentMethods: {
    value: [],
    error: '',
  },
  spokenLanguages: {
    value: [],
    error: '',
  },
  email: {
    value: '',
    error: '',
  },
  webSite: {
    value: '',
    error: '',
  },
  phone: {
    value: '',
    error: '',
    normalizedValue: '',
    valid: true,
  },
  tagline: {
    value: '',
    error: '',
  },
  mainDescription: {
    value: '',
    error: '',
    valid: false,
  },
  sessionDescription: {
    value: '',
    error: '',
    valid: null,
  },
  expectedBenefits: {
    value: '',
    error: '',
    valid: null,
  },
  photos: {
    value: [],
    error: '',
  },
  degrees: {
    value: [],
    error: '',
  },
})
const currentProfileId = ref(null)
const suggestDisciplineDialog = ref(false)
const alertProfile = ref(false)
const profiles = ref([])
const mainDescriptionEditor = ref()

const targetAudiences = ref([
  { id: 'all', name: $t('profileForm.targetAudiences.audience.all') },
  { id: 'adults', name: $t('profileForm.targetAudiences.audience.adults') },
  { id: 'children', name: $t('profileForm.targetAudiences.audience.children') },
  {
    id: 'adolescents',
    name: $t('profileForm.targetAudiences.audience.adolescents'),
  },
  { id: 'elderly', name: $t('profileForm.targetAudiences.audience.elderly') },
  {
    id: 'pregnantWomen',
    name: $t('profileForm.targetAudiences.audience.pregnantWomen'),
  },
  { id: 'women', name: $t('profileForm.targetAudiences.audience.women') },
  { id: 'men', name: $t('profileForm.targetAudiences.audience.men') },
])

const paymentMethods = ref([
  { id: 'cash', name: $t('profileForm.paymentMethods.method.cash') },
  {
    id: 'creditCard',
    name: $t('profileForm.paymentMethods.method.creditCard'),
  },
  { id: 'check', name: $t('profileForm.paymentMethods.method.check') },
  { id: 'transfer', name: $t('profileForm.paymentMethods.method.transfer') },
])

const durationUnits = ref([
  { id: 'years', name: $t('profileForm.degrees.durationUnit.years') },
  { id: 'months', name: $t('profileForm.degrees.durationUnit.months') },
  { id: 'weeks', name: $t('profileForm.degrees.durationUnit.weeks') },
])

const mainDisciplinesItems = computed(() => {
  // return only disciplines that are selected
  return disciplines.value.filter(discipline =>
    form.value.disciplines.value.some(
      selectedDiscipline => selectedDiscipline === discipline.id,
    ),
  )
})

const disciplinesRules = [
  (v: any) => v.length || $t('profileForm.disciplines.error.required'),
  (v: any) =>
    (v && v.length <= MAX_SPECIALITIES) ||
    $t('profileForm.disciplines.error.max'),
]
const practitionerServicesRules = [
  (v: any) =>
    (v && v.length <= MAX_PRACTITIONER_SERVICES) ||
    $t('profileForm.practitionerServices.error.max'),
]
const targetAudiencesRules = [
  (v: any) => v.length || $t('profileForm.targetAudiences.error.required'),
  // Disallow "all" if other audiences are selected
  (v: any) => {
    if (v && v.length > 1 && v.some((audience: any) => audience === 'all')) {
      return $t('profileForm.targetAudiences.error.allWithOthers')
    }
    return true
  },
]
const paymentMethodsRules = [
  (v: any) => v.length || $t('profileForm.paymentMethods.error.required'),
]
const spokenLanguagesRules = [
  (v: any) => v.length || $t('profileForm.spokenLanguages.error.required'),
  (v: any) =>
    5 >= v.length || $t('profileForm.spokenLanguages.error.maximum5Languages'),
]
const emailRules = [
  (v: any) =>
    !v.length || EmailRegEx.test(v) || $t('commonForm.email.error.invalid'),
]

const isValidDomain = (domain: string) => {
  const regex =
    /^(?!:\/\/)([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/
  return regex.test(domain) || $t('profileForm.validDomain')
}

const websiteRules = [
  (url: string) => {
    if (!url) return true
    try {
      const parsedUrl = new URL(url)
      const hostname = parsedUrl.hostname
      return isValidDomain(parsedUrl.hostname)
    } catch (_) {
      return $t('profileForm.invalidUrl')
    }
  },
]
const taglineRules = [
  (v: any) =>
    v.length <= SHORT_PRESENTATION_MAX_LENGTH ||
    $t('profileForm.tagline.error.max', {
      maxLength: SHORT_PRESENTATION_MAX_LENGTH,
    }),
]
const mainDescriptionRules = [
  (v: any) => !!v || $t('profileForm.mainDescription.error.required'),
  (v: any) =>
    v.length <= LONG_PRESENTATION_MAX_LENGTH ||
    $t('profileForm.mainDescription.error.max', {
      maxLength: LONG_PRESENTATION_MAX_LENGTH,
    }),
  (v: any) =>
    v.length > LONG_PRESENTATION_MIN_LENGTH ||
    $t('profileForm.mainDescription.error.min', {
      minLength: LONG_PRESENTATION_MIN_LENGTH,
    }),
]
const sessionDescriptionRules = [
  (v: any) =>
    v.length <= SESSION_DESCRIPTION_MAX_LENGTH ||
    $t('profileForm.sessionDescription.error.max', {
      maxLength: SESSION_DESCRIPTION_MAX_LENGTH,
    }),
]
const expectedBenefitsRules = [
  (v: any) =>
    v.length <= EXPECTED_BENEFITS_MAX_LENGTH ||
    $t('profileForm.expectedBenefits.error.max', {
      maxLength: EXPECTED_BENEFITS_MAX_LENGTH,
    }),
]
const requiredRules = [(v: any) => !!v || $t('commonForm.error.required')]
const isBaseForm1Valid = ref(true)
const isForm1Valid = computed(
  () => isBaseForm1Valid.value && form.value.phone.valid !== false,
)
const isForm2Valid = computed(
  () =>
    mainDescriptionEditor.value.isValid() !== false &&
    form.value.sessionDescription.valid !== false &&
    form.value.expectedBenefits.valid !== false,
)
const isForm3Valid = ref(true)
const formBlock1 = ref()
const formBlock2 = ref()
const formBlock3 = ref()

const currentYear = new Date().getFullYear()
const yearsRange = 80
const years = Array.from({ length: yearsRange }, (v, k) => currentYear - k)
const currentDocuments = ref([])

const handleUploadAvatar = () => {
  // @ts-ignore
  AvatarService.getPlugin('Dashboard').openModal()
}
const handleUploadPhotos = () => {
  setMaxNumberOfPhotos(MAX_PHOTOS - form.value.photos.value.length)
  // @ts-ignore
  PhotoService.getPlugin('Dashboard').openModal()
}
const handleUploadDegreeDocuments = currentFiles => {
  setMaxNumberOfDocuments(MAX_DOCUMENTS - currentFiles.length)
  currentDocuments.value = currentFiles
  // @ts-ignore
  DocumentService.getPlugin('Dashboard').openModal()
}

AvatarService.on('complete', result => {
  const avatar = result.successful[0]
  form.value.avatar.src = avatar.preview
  form.value.avatar.key = avatar.uploadURL
})

PhotoService.on('complete', result => {
  const uploadedPhotos = result.successful
  uploadedPhotos
    .slice(0, MAX_PHOTOS - form.value.photos.value.length)
    .forEach((photo: any) => {
      form.value.photos.value.push({
        src: photo.preview,
        key: photo.uploadURL,
      })
    })
})

DocumentService.on('complete', result => {
  const uploadedDocuments = result.successful
  uploadedDocuments
    .slice(0, MAX_PHOTOS - currentDocuments.value.length)
    .forEach((document: any) => {
      currentDocuments.value.push({
        src: document.preview,
        key: document.uploadURL,
        name: document.name,
      })
    })
})

const onPhoneChange = async phoneObject => {
  form.value.phone.normalizedValue = phoneObject.number
}

const addDegree = () => {
  const newDegree = JSON.parse(JSON.stringify(degreeForm.value))
  form.value.degrees.value.push(newDegree)
}

const disciplines = ref([])
const practitionerServices = ref([])
const client = createHttpClient()

const fetchSelectData = () => {
  Promise.all([
    client.get('/discipline/list'),
    client.get('/practitioner-service/list'),
  ])
    .then(([disciplinesResponse, practitionerServicesResponse]) => {
      disciplines.value = disciplinesResponse.data
      practitionerServices.value = practitionerServicesResponse.data
    })
    .catch(error => {
      console.error('Error fetching data:', error) // Gestion des erreurs
    })
}

const getFormValues = form => {
  return Object.keys(form).reduce((acc, key) => {
    if (typeof form[key] === 'object' && form[key].value) {
      acc[key] = form[key].value
    }
    return acc
  }, {})
}

const submitLoading = ref(false)
const rootErrors = ref<string[]>([])
const newProfile = ref(false)

const submit = async () => {
  rootErrors.value = []
  resetErrors(form.value)
  await formBlock1.value.isValid()
  await nextTick()
  if (
    !isForm1Valid.value ||
    !isForm2Valid.value ||
    !(await formBlock3.value.isValid())
  ) {
    scrollToFirstError()
    return Promise.reject('Form not valid')
  }

  const data = {
    ...getFormValues(form.value),
    phone: form.value.phone.normalizedValue,
    avatar: form.value.avatar.key || '',
    disciplines: form.value.disciplines.value,
    practitionerServices: form.value.practitionerServices.value,
    targetAudiences: form.value.targetAudiences.value,
    paymentMethods: form.value.paymentMethods.value,
    spokenLanguages: form.value.spokenLanguages.value,
    photos: form.value.photos.value.map((photo: any) => photo.key),
    degrees: form.value.degrees.value.map((degree: any) => ({
      schoolName: degree.schoolName.value,
      discipline: degree.discipline.value,
      title: degree.title.value,
      graduationYear: degree.graduationYear.value,
      durationValue: degree.durationValue.value,
      durationUnit: degree.durationUnit.value,
      documents: degree.documents.value.map((document: any) => document.key),
    })),
  }
  submitLoading.value = true
  const client = createHttpClient('/profile')
  const url = currentProfileId.value ? `/${currentProfileId.value}` : '/'
  return client
    .post(url, data)
    .then(response => {
      currentProfileId.value = response.data.id
      if (!newProfile.value) {
        MessageService.success($t('dashboard.account.update.success'))
      } else {
        // update dashboard status
        dashboardState.fetchDashboardStatus()
        MessageService.success($t('dashboard.profile.success'))
        newProfile.value = false
      }
      loadProfiles()
      return Promise.resolve()
    })
    .catch(async err => {
      console.error(err)
      if (err.response.status === 422) {
        rootErrors.value = handleServerErrors(form.value, err.response.data)
        console.log('error ===>>>', rootErrors.value)
        await nextTick()
        scrollToFirstError()
      } else {
        MessageService.error($t('dashboard.account.update.error'))
      }
      return Promise.reject('Error submitting form')
    })
    .finally(() => {
      submitLoading.value = false
    })
}

const loadProfiles = () => {
  appStore.setInnerLoading(true)
  const client = createHttpClient('/profile')
  client
    .get('/')
    .then(response => {
      profiles.value = response.data
      if (profiles.value.length === 0) {
        newProfile.value = true
        return
      }
      const currentProfile = profiles.value[0]

      assignDataToFrom(currentProfile)
    })
    .catch(err => {
      console.error(err)
      MessageService.error($t('common.error.errorHasOccurred'))
    })
    .finally(() => {
      appStore.setInnerLoading(false)
    })
}

watch(submitLoading, value => {
  emit('submitLoading', value)
})

defineExpose({
  submit,
})

onMounted(async () => {
  fetchSelectData()
  loadProfiles()
})

const deleteProfil = async profil_id => {
  try {
    await client.delete(`/profile/${profil_id}`)
    MessageService.success($t('dashboard.profile.delete_message'))
    // update dashboard status
    dashboardState.fetchDashboardStatus()
    return Promise.resolve()
  } catch (err) {
    MessageService.error($t('dashboard.profile.delete_error'))
  } finally {
    await loadProfiles()
  }
}

const profileSelected = profil => {
  const newProfilForm = document.getElementById('newProfil')
  newProfilForm.scrollIntoView({ behavior: 'smooth' })
  assignDataToFrom(profil)
}

const mainDescriptionEditorKey = ref(0)
const expectedBenefitsEditorKey = ref(0)
const sessionDescriptionEditorKey = ref(0)
const disciplineEditorKey = ref(0)
const audiencesEditorKey = ref(0)
const paymentEditorKey = ref(0)
const spokenLanguagesEditorKey = ref(0)

const new_profile = () => {
  const newProfilForm = document.getElementById('newProfil')
  newProfilForm.scrollIntoView({ behavior: 'smooth' })
  newProfile.value = true
  currentProfileId.value = ''
  mainDescriptionEditorKey.value++
  expectedBenefitsEditorKey.value++
  sessionDescriptionEditorKey.value++
  disciplineEditorKey.value++
  audiencesEditorKey.value++
  paymentEditorKey.value++
  spokenLanguagesEditorKey.value++

  form.value = {
    ...form.value,
    mainDescription: { ...form.value.mainDescription, value: '' },
    avatar: { ...form.value.avatar, src: '' },
    disciplines: { ...form.value.disciplines, value: [] },
    mainDiscipline: { ...form.value.mainDiscipline, value: '' },
    practitionerServices: { ...form.value.practitionerServices, value: [] },
    webSite: { ...form.value.webSite, value: '' },
    email: { ...form.value.email, value: '' },
    phone: { ...form.value.phone, value: '' },
    degrees: { ...form.value.degrees, value: [] },
    photos: { ...form.value.photos, value: [] },
    expectedBenefits: { ...form.value.expectedBenefits, value: '' },
    sessionDescription: { ...form.value.sessionDescription, value: '' },
    targetAudiences: { ...form.value.sessionDescription, value: [] },
    paymentMethods: { ...form.value.paymentMethods, value: [] },
    spokenLanguages: { ...form.value.spokenLanguages, value: [] },
    tagline: { ...form.value.tagline, value: '' },
  }
}

const assignDataToFrom = payload => {
  currentProfileId.value = payload.id
  form.value.avatar.src = payload.avatar?.url
  form.value.disciplines.value = payload.disciplines.map(
    (discipline: any) => discipline.id,
  )
  form.value.mainDiscipline.value = payload.mainDiscipline?.id
  form.value.practitionerServices.value = payload.services.map(
    (service: any) => service.id,
  )
  form.value.targetAudiences.value = payload.targetAudiences
  form.value.paymentMethods.value = payload.paymentMethods
  form.value.spokenLanguages.value = payload.spokenLanguages
  form.value.email.value = payload.publicEmail || ''
  form.value.webSite.value = payload.webSite || ''
  form.value.phone.value = payload.publicPhone || ''
  form.value.tagline.value = payload.tagline
  form.value.mainDescription.value = payload.mainDescription
  form.value.sessionDescription.value = payload.sessionDescription
  form.value.expectedBenefits.value = payload.expectedBenefits
  form.value.photos.value = payload.photos.map((photo: any) => ({
    src: photo.url,
    key: photo.id,
  }))
  form.value.degrees.value = payload.degrees.map((degree: any) => ({
    schoolName: { value: degree.schoolName },
    discipline: { value: degree.discipline },
    title: { value: degree.title },
    graduationYear: { value: degree.graduationYear },
    durationValue: { value: degree.durationValue },
    durationUnit: { value: degree.durationUnit },
    documents: {
      value: degree.verificationDocuments.map(
        (document: any, docIndex: number) => ({
          src: document.url,
          key: document.id,
          name: document.name || `Document ${docIndex + 1}`,
        }),
      ),
    },
  }))
}

const seeDocument = url => {
  window.open(url, '_blank')
}
</script>

<style lang="scss">
.profile-selected {
  background-color: rgba(0, 0, 0, 0.04);
}
.uppy-StatusBar-actionBtn--done {
  border-radius: 3px;
  display: block !important;
  background-color: #1a73e9;
  color: white;
  outline: none;
}

.uppy-StatusBar-actionBtn--done:hover {
  color: white;
}

.uppy-StatusBar-actionBtn--done:focus {
  background-color: #1a73e9;
  color: white;
}

.uppy-Dashboard-close {
  background-color: red;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-sizing: border-box;
}
.uppy-Dashboard-close span {
  line-height: 1;
  padding-bottom: 6px;
}
</style>
