import { Ref, ref, shallowRef } from 'vue'
import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import createHttpClient from '@/api/httpClient'
import { AxiosResponse } from 'axios'
import { AgendaResponse, AgendaTimeSlot } from '@/api/timeslot/timeslot'
import { InitTimeSlotParams, TimeSlotEvent } from './timeSlotEvent'
import {
  CalendarID,
  calendarApp,
  colorAppointmentReasons,
  profileColors,
  scrollController,
} from '@/components/calendar/calendarApp'
import { useUserStore } from '../user/userStore'
import MessageService from '@/components/feedback/message/messageService'
import { ProfileInformationData } from '@/api/profile/profile.d'
import { AddressInformationData } from '@/api/account/address'
import { useVacationEventStore } from '../vacation/vacationEventStore'
import LoadingBackdropService from '@/components/feedback/loadingBackdrop/loadingBackdropService'
import { useDialogTimeSlotStore } from './dialogTimeSlotStore'
import {
  VerifyTimeSlotParams,
  VerifyTimeSlotResponse,
} from '@/api/timeslot/verify'
import { ApiResponse } from '@/api/api'
import ConfirmDialogService from '@/components/feedback/confirmDialog/confirmDialogService'
import {
  ValidateTimeSlotParams,
  ValidateTimeSlotResponse,
} from '@/api/timeslot/validate'
import { useAppointmentReasonStore } from '../appointment-reason/appointementReasonStore'
import EventBus from '@/utils/eventBus'
import { calendarsUpdaterPlugin } from '@/components/calendar/calendarState'
import i18n from '@/plugins/i18n'
import { FormattedAppointmentReasonInformationData } from '@/api/appointment-reason/appointment-reason'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useDashboardStatus } from '../dashboard-status/dashboardStatus'

dayjs.extend(utc)
dayjs.extend(timezone)

const httpClient = createHttpClient()
const { t } = i18n.global

export const useTimeSlotStore = defineStore('timeslot', () => {
  const timeSlots: Ref<TimeSlotEvent[]> = shallowRef([])
  const dashboardState = useDashboardStatus()
  const dialogStore = useDialogTimeSlotStore()

  const fillTimeSlots = async (
    profileIds: string[],
    addressIds: string[],
    appointmentReasonIds: string[],
    ownerId,
    start,
    end,
  ) => {
    try {
      const timeSlotResponse: AxiosResponse<AgendaResponse> =
        await httpClient.get(
          `/agenda/agenda?profiles=${profileIds.join(',')}&addresses=${addressIds.join(',')}` +
            `&appointmentReason=${appointmentReasonIds.join(',')}` +
            `&start=${start}&end=${end}` +
            `&owner=${ownerId ? ownerId : ''}`,
        )
      if (timeSlotResponse.status !== 200) {
        throw Error(JSON.stringify(timeSlotResponse))
      }

      timeSlots.value = formatResponse(timeSlotResponse.data.timeslots)
    } catch (error) {
      console.error(error)
    }
  }

  const formatResponse = (timeSlots: AgendaTimeSlot[]): TimeSlotEvent[] => {
    const appointementReasonStore = useAppointmentReasonStore()
    return [
      ...timeSlots.map((timeSlot: AgendaTimeSlot): TimeSlotEvent => {
        const originalTimeSlot = timeSlot.timeslot
        const { start, end } = timeSlot

        let acceptRemote: 'in-person' | 'remote' | 'both' = 'in-person'
        if (originalTimeSlot.presential && originalTimeSlot.remote) {
          acceptRemote = 'both'
        } else if (originalTimeSlot.presential) {
          acceptRemote = 'in-person'
        } else if (originalTimeSlot.remote) {
          acceptRemote = 'remote'
        }

        let weekRepeat: 'yes' | 'no' = 'yes'
        if (!originalTimeSlot.weekRepeat) {
          weekRepeat = 'no'
        }

        return {
          id: timeSlot.id,
          type: 'TimeSlotEvent',
          start: dayjs
            .utc(start)
            .tz(dayjs.tz.guess())
            .format('YYYY-MM-DD HH:mm'),
          end: dayjs.utc(end).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm'),
          title: originalTimeSlot.appointmentReasons[0].label,
          description: originalTimeSlot.appointmentReasons
            .map(ar => `${ar.label} : ${ar.description}`)
            .join('\n\n'),
          location: `Remote : ${originalTimeSlot.remote}, Presential : ${originalTimeSlot.presential}`,
          calendarId: String(CalendarID.PROFILE_1),
          appointmentReasons: originalTimeSlot.appointmentReasons.map(
            (reason): FormattedAppointmentReasonInformationData => ({
              ...reason,
              remote: reason.remote ? 'yes' : 'no',
              profile: { id: originalTimeSlot.agenda.profile.id },
            }),
          ),
          profileId: originalTimeSlot.agenda.profile.id,
          addressId: originalTimeSlot.agenda.address.id,
          acceptRemote,
          days: originalTimeSlot.days.sort(),
          weekRepeat,
          weekRepetition: originalTimeSlot.weekRepetition
            ? originalTimeSlot.weekRepetition
            : 1,
          timeSlotId: originalTimeSlot.id,
          profileColor:
            profileColors[
              userStore.profiles.findIndex(
                p => p.id == originalTimeSlot.agenda.profile.id,
              )
            ],
          appointmentReasonColor:
            colorAppointmentReasons[
              appointementReasonStore.appointmentReasons.findIndex(
                a => a.id == originalTimeSlot.appointmentReasons[0].id,
              )
            ],
        }
      }),
    ]
  }

  const getAddressIds: (addressItems: AddressInformationData[]) => string[] = (
    addressItems: AddressInformationData[],
  ) => addressItems.map(a => a.id)

  const getProfileIds: (profileItems: ProfileInformationData[]) => string[] = (
    profileItems: ProfileInformationData[],
  ) => profileItems.map(a => a.id)
  const errors = ref('')
  const userStore = useUserStore()
  const vacationStore = useVacationEventStore()
  const initTimeSlots = async (
    {
      profileIds,
      addressIds,
      appointmentReasonIds,
      ownerId,
      start,
      end,
    }: InitTimeSlotParams = {
      profileIds: null,
      addressIds: null,
      appointmentReasonIds: null,
      ownerId: null,
      start: null,
      end: null,
    },
  ) => {
    try {
      LoadingBackdropService.start()
      calendarApp.value.events.set([])
      await vacationStore.initVacations()

      await userStore.initAddresses()
      if (!addressIds) {
        addressIds = getAddressIds(userStore.addresses)
      }

      await userStore.initProfiles()
      if (!profileIds) {
        profileIds = getProfileIds(userStore.profiles)
      }

      const appointReasonStore = useAppointmentReasonStore()
      await appointReasonStore.fillAppointmentReasons()
      if (!appointmentReasonIds) {
        appointmentReasonIds = appointReasonStore.appointmentReasons.map(
          ar => ar.id,
        )
      }

      if (start === null) {
        start = dayjs(
          calendarsUpdaterPlugin.$app.calendarState.range.value.start,
          'YYYY-MM-DD HH:mm',
        ).toISOString()
      }

      if (end === null) {
        end = dayjs(
          calendarsUpdaterPlugin.$app.calendarState.range.value.end,
          'YYYY-MM-DD HH:mm',
        )
          .add(1, 'minute')
          .toISOString()
      }

      await fillTimeSlots(
        profileIds,
        addressIds,
        appointmentReasonIds,
        ownerId,
        start,
        end,
      )
      const events = [...timeSlots.value, ...vacationStore.vacations]
      calendarApp.value.events.set(events)
      scrollToFirstHour()
    } catch (error) {
      console.error(error)
      MessageService.error(t('dashboard.time-slot.errorGetTimeSlot'))
    }
    LoadingBackdropService.stop()
  }

  const scrollToFirstHour = () => {
    function trouverDateHeureLaPlusTot(dates) {
      // Initialiser le tableau des objets dayjs
      const datesConverties = dates.map(date => dayjs(date, 'YYYY-MM-DD HH:mm'))

      // Trouver la date avec l'heure la plus tôt
      const dateMin = datesConverties.reduce((a, b) => {
        return a.hour() < b.hour() ||
          (a.hour() === b.hour() && a.minute() < b.minute())
          ? a
          : b
      })

      return dateMin.format('YYYY-MM-DD HH:mm')
    }

    if (!timeSlots.value.length) return

    const dateAvecHeureLaPlusTot = trouverDateHeureLaPlusTot(
      timeSlots.value.map(t => t.start),
    )

    scrollController.scrollTo(dateAvecHeureLaPlusTot.split(' ')[1])
  }

  const loading = ref(false)
  const handleCreateOrUpdateTimeSlot = async (
    verifyPayload: VerifyTimeSlotParams,
    change: ChangeParams,
  ) => {
    const NO_VERIFY = true // old behaviour
    try {
      loading.value = true
      let response: ApiResponse<VerifyTimeSlotResponse>
      if (!NO_VERIFY) {
        if (!dialogStore.isEditing) {
          response = await httpClient.post(`/timeslot/verify`, verifyPayload, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
        } else {
          if (verifyPayload.weekRepeat) {
            verifyPayload.date = null
          }
          response = await httpClient.put(
            `/timeslot/${dialogStore.timeSlotId}/verify${verifyPayload.weekRepeat ? '?change=' + change : ''}`,
            verifyPayload,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          )
        }
        if (response.status == 422) {
          MessageService.error(response.data.message)
        } else if (response.status != 200) {
          throw Error(JSON.stringify(response))
        }
      } else if (NO_VERIFY) {
        if (verifyPayload.weekRepeat) {
          verifyPayload.date = null
        }
      }

      if (dialogStore.isEditing && dialogStore.repeatTimeSlot === 'yes') {
        // Special case for edition with reccuring events
        EventBus.emit('openModalConfirm', {
          mode: 'edition',
          message: NO_VERIFY ? '' : response.data.message,
          messageChangeAll: NO_VERIFY ? '' : response.data.messageChangeAll,
          messageChangeSingle: NO_VERIFY
            ? ''
            : response.data.messageChangeSingle,
          change,
        })
      } else {
        let message
        if (NO_VERIFY) {
          message = dialogStore.isEditing
            ? t('dashboard.time-slot.confirmEditTimeSlotMessage')
            : t('dashboard.time-slot.confirmCreateTimeSlotMessage')
        }
        ConfirmDialogService.confirm({
          title: dialogStore.isEditing
            ? t('dashboard.time-slot.confirmEditTimeSlot')
            : t('dashboard.time-slot.confirmCreateTimeSlot'),
          message: NO_VERIFY ? message : response.data.message,
          optionsConfirm: {
            onCancel() {},
            onConfirm() {
              handleConfirmTimeSlot(verifyPayload, change)
            },
          },
          cancelBtnLabel: t('dashboard.time-slot.cancel'),
          confirmBtnLabel: t('dashboard.time-slot.confirm'),
        })
      }
    } catch (err) {
      console.error('error in create or udate', err)
      MessageService.error(err.response?.data?.message)
    }

    loading.value = false
  }

  type ChangeParams = 'removeAfterDate' | 'single' | 'all'
  const handleConfirm = {
    create: {
      call: async (
        payload: ValidateTimeSlotParams,
        change: ChangeParams = 'single',
      ) => {
        return await httpClient.post(`/timeslot/validate`, payload, {
          headers: { 'Content-Type': 'application/json' },
        })
      },
      success: () => {
        MessageService.success(t('dashboard.time-slot.successCreateTimeSlot'))
      },
      error: () => {
        MessageService.error(t('dashboard.time-slot.errorCreateTimeSlot'))
      },
    },
    edit: {
      call: async (
        payload: ValidateTimeSlotParams,
        change: ChangeParams = 'single',
      ) => {
        try {
          return await httpClient.put(
            `/timeslot/${dialogStore.timeSlotId}/validate?change=${change}`,
            payload,
            {
              headers: { 'Content-Type': 'application/json' },
            },
          )
        } catch (e) {
          console.error(e)
        }
      },
      success: () => {
        MessageService.success(t('dashboard.time-slot.successUpdateTimeSlot'))
      },
      error: () => {
        MessageService.error(t('dashboard.time-slot.errorUpdateTimeSlot'))
      },
    },
  }

  const handleConfirmTimeSlot = async (
    validateTimeSlotPayload: ValidateTimeSlotParams,
    change: ChangeParams,
  ) => {
    errors.value = ''
    const methodsConfirm = dialogStore.isEditing
      ? handleConfirm.edit
      : handleConfirm.create

    LoadingBackdropService.start()

    try {
      const response: ApiResponse<ValidateTimeSlotResponse> =
        await methodsConfirm.call(validateTimeSlotPayload, change)
      if (response.status != 200) throw Error(JSON.stringify(response))

      methodsConfirm.success()

      // update dashboard status
      dashboardState.fetchDashboardStatus()

      await initTimeSlots()
      if (dialogStore.isDialogTimeSlotOpen) {
        dialogStore.toggleDialogTimeSlot()
      }
    } catch (err) {
      methodsConfirm.error()
      errors.value = err.response.data.message
      console.error(err)
    }
    EventBus.emit('closeTimeslotConfirmDialog')
    LoadingBackdropService.stop()
  }

  return {
    timeSlots,
    loading,
    initTimeSlots,
    formatResponse,
    handleCreateOrUpdateTimeSlot,
    handleConfirmTimeSlot,
    errors,
  }
})
